@use "src/style/main" as *;

.ApplicationBase {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
  &__left {
    width: 50%;
    height: 100%;
    background: $background-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-mobile {
      width: 100%;
    }

    &-top {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      max-width: 695px;
      padding: 0 20px;
    }
  }
  &__logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 15%;
    &-icon {
      width: 200px;
    }
  }

  &__form {
    height: 90%;
  }

  &__language {
    height: 15%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px;
  }
  &__applied-job-card {
    font-family: "Manrope-Bold", sans-serif;
    color: $primary-font-color;
    margin-top: 20px;
    margin-left: 50px;
    margin-right: 50px;
    text-align: center;
    //width: 100%;
    border-radius: 14px;
    box-shadow: 0px 0px 20px 4px #cecece40;
    background-color: #ffffff;
    position: relative;
    padding: 10px;
    transition: all 300ms ease-in-out;
    border: 1px solid #d3d2d2;
    align-self: center;
    cursor: pointer;
    &:hover {
      box-shadow:
              rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
              rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }
  }

}

@include mobile {
  .ApplicationBase {
    height: 100vh; // Use full viewport height
    &__left-top {
      height: 100vh;
      padding-bottom: 80px; // Space for the button
    }
    &__form {
      height: calc(100% - 60px); // Adjust for header
    }
  }
}

@include tablet {
  .ApplicationBase {
    &__circle {
      &-tip {
        &--icon {
          width: 30px;
          height: 30px;
        }
        &--text {
          font-size: 20px;
          padding-left: 10px;
        }
      }
    }
  }
}
