@use "src/style/main" as *;

.EditProfileSettingsContainer {
  background: $white-color;
  border-radius: 10px;
  border: 0.5px solid rgba(19, 35, 66, 0.1);
  box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);

  &__head {
    padding: 20px;
    border-bottom: 0.5px solid rgba(19, 35, 66, 0.1);
    &-title {
      font-family: "Manrope-Bold", sans-serif;
      font-size: 20px;
      color: $primary-font-color;
    }
  }

  &__body {
    padding: 20px;
  }
}

.EditProfileSettings {
  &__noData {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Manrope-Bold", sans-serif;
    color: $primary-font-color;
  }

  &__loader {
    height: 300px;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__title {
    font-family: "Manrope-Bold", sans-serif;
    font-size: 18px;
    color: $primary-font-color;
    display: flex;
    justify-content: center;
    align-items: center;

    &-back {
      width: 18px;
      height: 18px;
      rotate: 90deg;
      margin-right: 15px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__form {
    display: grid;
    grid-template-columns: 1fr 4fr;
  }

  &__inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
  }

  &__uploader {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
    margin-right: 30px;

    &-error {
      text-align: left;
      margin: 20px 0;
      font-family: "Manrope-Bold", sans-serif;
      color: $error-color;
    }
  }

  &__circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;
    border: 1px solid #ebebeb;
  }

  &__placeholder {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }

  &__pen {
    position: absolute;
    bottom: -15px;
    right: -15px;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
  }

  &__input {
    margin-bottom: 20px;
    flex-grow: 1;
    &-error {
      text-align: right;
      margin: 5px 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $error-color;
    }
    &-description {
      text-align: left;
      margin: 5px 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $secondary-font-color;
      font-size: 12px;
    }
  }

  &__switch {
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-column: 1/3;

    &:last-child {
      margin-bottom: 30px;
    }

    &-label {
      font-family: "Manrope-Bold", sans-serif;
      text-transform: uppercase;
      color: $primary-font-color;
      letter-spacing: 0.96px;
    }
  }

  &__footer {
    grid-column: 1/3;
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    grid-column: 2;
    max-width: 200px;
  }
}

.DeleteCardModal {
  border-radius: 14px;
  border: 0.5px solid rgba(19, 35, 66, 0.1);
  background: $white-color;
  box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
  padding: 20px;
  max-width: 400px;

  &__question {
    font-size: 20px;
    padding: 20px;
    text-align: center;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    &-button {
      height: 50px;
      width: 48%;
    }
  }
}

@include mobile {
  .EditProfileSettings {
    &__uploader {
      align-items: center;
    }

    &__form {
      grid-template-columns: 1fr;
    }

    &__inputs {
      grid-template-columns: 1fr;
    }

    &__textArea {
      grid-column: 1/1;
    }

    &__switch {
      grid-column: 1/1;
      padding: 15px 10px;
    }

    &__footer {
      grid-column: 1/1;
    }
    &__button {
      max-width: 100%;
    }
  }
}
