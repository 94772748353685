@use "src/style/main" as *;

.ShortTermNotAssigned {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px 0;
  min-height: 680px;
  height: 100%;
  border: 0.5px solid rgba(19, 35, 66, 0.1);
  box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);

  &__public-id-link-title {
    font-size: 16px;
    color: $secondary-font-color;
    font-family: "Manrope-Bold", sans-serif;
    padding: 0 15px 0 20px;
  }
  &__public-id-link-title-container {
    display: flex;
    align-items: center;
    box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
    //margin-bottom: 20px;
  }


  &__main-title {
    font-size: 20px;
    font-family: "Manrope-Bold", sans-serif;
    padding: 0 15px 0 20px;
  }

  &__title-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__reload-icon {
    margin-top: 2px;
    margin-right: 10px;
    width: 18px;
    height: 18px;
    opacity: 1;

    &:hover {
      cursor: pointer;
    }
  }

  &__reload-icon--disable {
    opacity: 0.4;

    &:hover {
      cursor: not-allowed;
    }
  }

  &__container {
    display: flex;
    border-bottom: 0.3px solid rgba(200, 200, 200, 0.3);
    padding: 0 25px;
  }

  &__container-item {
    font-size: 14px;
    line-height: 13.16px;
    font-family: "Manrope-Bold", sans-serif;
    color: #b2b2b2;
    margin-right: 30px;
    padding-bottom: 10px;
    cursor: pointer;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1.2px;
      bottom: -1px;
      left: 0;
      background: linear-gradient(265.82deg, #aee6ff 44.76%, #030ac0 58.06%);
      transform-origin: bottom right;
      transition: transform 300ms ease-out;
      visibility: hidden;
      z-index: 2;
    }

    &--active {
      color: #132342;

      &::before {
        transform: scaleX(1);
        transform-origin: bottom left;
        visibility: visible;
      }
    }
  }
  &__download-icon {
    width: 15px;
    height: 15px;
    margin-left: 4px;
    opacity: 1;
    //padding: 2px;
    &:hover {
      cursor: pointer;
    }
  }
  &__button {
    width: fit-content;
    border-radius: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 24px 5px 5px;
    position: relative;
    z-index: 2;
    border: 0.1px solid rgba(19, 35, 66, 0.1);
    box-shadow: 0px 0px 20px 4px #cecece40;
    margin-right: 20px;

    &:hover {
      cursor: pointer;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    &-round {
      background: linear-gradient(222.56deg, #aee7ff -6.49%, #030ac0 106.4%);
      border-radius: 50%;
      width: 37px;
      height: 37px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 14px;

      &--plus {
        width: 20px;
        height: 20px;
        border-radius: 0.4px;
      }
    }

    &-text {
      color: #132342;
      font-family: "Manrope-Bold", sans-serif;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 13px;
      letter-spacing: 0.8px;
    }
  }

  &__no-content {
    text-align: center;
    font-size: 16px;
    font-family: "Manrope-Bold", sans-serif;
    margin-top: 150px;
  }

  &__header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .ShortTermNotAssigned__button-wrapper {
    @media (max-width: 768px) {
      padding-left: 1rem; // Adds padding to the left when on a new row
    }
  }

  &__buttons-container {
    display: flex;
    align-items: center;
    margin-top: 25px;
    padding-left: 20px;
  }

  &__not-assigned-btn {
    border: 0.2px solid rgba(19, 35, 66, 0.2);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 4px 0px;
    border-radius: 21px;
    font-size: 14px;
    line-height: 13px;
    font-family: "Manrope-Bold", sans-serif;
    color: #132342;
    padding: 14.5px 22px;
    margin-right: 10px;

    &--active {
      background: linear-gradient(222.56deg, #aee7ff -6.49%, #030ac0 106.4%);
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 4px 0px;
      color: #ffffff;
      border: none;
    }

    &:hover {
      cursor: pointer;
      box-shadow: 0px 0px 20px 4px #cecece40;
    }
  }

  &__download-btn {
    border: 0.2px solid rgba(19, 35, 66, 0.2);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 4px 0px;
    border-radius: 21px;
    font-size: 14px;
    line-height: 13px;
    font-family: "Manrope-Bold", sans-serif;
    color: #132342;
    padding: 11px 11px;
    @media (max-width: 768px) {
      padding-right: 1rem; // Adds padding to the left when on a new row
    }

    &:hover {
      cursor: pointer;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
  }

  &__assigned-btn {
    border: 0.2px solid rgba(19, 35, 66, 0.2);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 4px 0px;
    border-radius: 21px;
    font-size: 14px;
    line-height: 13px;
    font-family: "Manrope-Bold", sans-serif;
    color: #132342;
    padding: 13.5px 14px;
    margin-right: 10px;

    &--active {
      background: linear-gradient(222.56deg, #aee7ff -6.49%, #030ac0 106.4%);
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 4px 0px;
      color: #ffffff;
      border: none;
    }

    &:hover {
      cursor: pointer;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
  }

  &__timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;

    &::after {
      content: "";
      position: absolute;
      width: 0.4px;
      background: rgba(215, 215, 215, 0.4);
      border: 0.4px solid rgba(215, 215, 215, 0.4);
      top: 40px;
      bottom: -20px;
      left: 40px;
      margin-left: -3px;
    }
  }

  &__timeline-container {
    padding: 20px 80px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 43px;
      height: 43px;
      left: 17px;
      background: linear-gradient(222.56deg, #aee7ff -6.49%, #030ac0 106.4%);
      top: 21px;
      border-radius: 50%;
      z-index: 1;
    }
  }

  &__timeline-content {
    //flex-grow: 1;
    //display: flex;
    //flex-wrap: wrap;
    //gap: 20px;
    //align-items: stretch;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }

  &__timeline-dot {
    position: absolute;
    width: 43px;
    height: 43px;
    left: 17px;
    background-color: transparent;
    top: 20px;
    border-radius: 50%;
    z-index: 2;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 7px;
  }

  &__timeline-dot-weekday,
  &__timeline-dot-day {
    color: #ffffff;
    font-family: "Manrope-ExtraBold", sans-serif;
  }

  &__timeline-dot-weekday {
    font-size: 10px;
    line-height: 10px;
  }

  &__timeline-dot-day {
    font-size: 21px;
    line-height: 21px;
  }

  &__job-wrapper {
    display: flex;
  }

  &__spinner {
    width: 14px;
    margin-left: 4px;
    padding: 2px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: $primary-font-color;
    --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: l3 1s infinite linear;
  }

  @keyframes l3 {
    to {
      transform: rotate(1turn);
    }
  }
}

@include mobile {
  .ShortTermNotAssigned {
    &__container {
      padding: 0;
      border-top: 0.3px solid rgba(200, 200, 200, 0.3);
      margin-top: 20px;
    }

    &__buttons-container {
      justify-content: center;
      margin-top: 20px;
      padding-left: 0;
    }

    &__container-item {
      margin-right: 0;
      width: 100%;
      padding: 20px;
      text-align: center;
    }

    &__assigned-btn {
      padding: 10px;
      text-align: center;
      font-size: 13px;
    }

    &__not-assigned-btn {
      padding: 10px;
      text-align: center;
      font-size: 13px;
      margin-right: 20px;
    }

    &__timeline-container {
      padding: 20px 20px 20px 40px;

      &::after {
        width: 30px;
        height: 30px;
        left: 5px;
        top: 21px;
      }
    }

    &__timeline-dot {
      width: 30px;
      height: 30px;
      left: 5px;

      &-weekday {
        font-size: 8px;
        line-height: 8px;
      }

      &-day {
        font-size: 12px;
        line-height: 12px;
      }
    }

    &__timeline {
      &::after {
        left: 20px;
        margin-left: 0;
      }
    }
  }
}
