@use "src/style/main" as *;

.RightSidebar {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 49;

  &__container {
    overflow-y: visible;
    max-width: 479px;
    width: 100%;
    height: calc(100% - 95px);
    background-color: #ffffff;
    position: absolute;
    right: 20px;
    top: 95px;
    z-index: 502;
    border-radius: 10px 10px 0 0;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 20px 4px #cecece40;
    border: 1px solid #e4e4e4;
  }

  &--opened {
    display: flex;
  }
  &__header {
    position: relative;
    width: 100%;
  }
  &__close {
    position: absolute;
    width: 13px;
    height: 13px;
    right: 20px;
    top: 20px;
    cursor: pointer;
    z-index: 10;
  }
}

@include mobile {
  .RightSidebar {
    width: 100%;
    height: 100%;
    &__container {
      right: 0;
    }

    &__body {
      width: 100%;
    }
  }
}
