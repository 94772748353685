@use "src/style/main" as *;

.PaymentCard {
  border-radius: 14px;
  border: 0.5px solid rgba(19, 35, 66, 0.1);
  background: $white-color;
  box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__container {
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border: 0.5px solid $secondary-font-color;
    border-radius: 50%;
    object-fit: cover;
  }

  &__type {
    color: $secondary-font-color;
    font-size: 12px;
    letter-spacing: 1.44px;
    text-transform: uppercase;
  }

  &__expire {
    display: flex;
    align-items: center;

    &-icon {
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }

    &-date {
      font-size: 10px;
    }
  }

  &__default {
    color: $secondary-font-color;
    font-size: 12px;
    letter-spacing: 1.44px;
    text-transform: uppercase;
  }

  &__right {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.PaymentCardDropdown {
  position: relative;
  &__icon {
    padding: 3px;
    height: 22px;

    &:hover {
      cursor: pointer;
    }
  }

  &__body {
    position: absolute;
    right: 0;
    top: 28px;
    border: 0.5px solid rgba(19, 35, 66, 0.1);
    background: $white-color;
    box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
    border-radius: 8px;

    &-item {
      white-space: nowrap;
      padding: 10px 25px;
      text-align: center;
      font-size: 12px;
      letter-spacing: 1.2px;
      text-transform: uppercase;

      &:first-child {
        border-radius: 8px 8px 0 0;
      }
      &:last-child {
        border-radius: 0 0 8px 8px;
      }

      &:hover {
        cursor: pointer;
        background: linear-gradient(223deg, #aee7ff -6.49%, #030ac0 106.4%);
        color: $white-color;
      }
    }
  }
}

.MakePrimaryCardModal {
  border-radius: 14px;
  border: 0.5px solid rgba(19, 35, 66, 0.1);
  background: $white-color;
  box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
  padding: 20px;
  max-width: 400px;

  &__question {
    font-size: 20px;
    padding: 20px;
    text-align: center;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    &-button {
      height: 50px;
      width: 48%;
    }
  }
}
.DeleteCardModal {
  border-radius: 14px;
  border: 0.5px solid rgba(19, 35, 66, 0.1);
  background: $white-color;
  box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
  padding: 20px;
  max-width: 400px;

  &__question {
    font-size: 20px;
    padding: 20px;
    text-align: center;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    &-button {
      height: 50px;
      width: 48%;
    }
  }
}
