@use "src/style/main" as *;

.Dashboard {
}

.react-calendar__tile.selected {
  background: #007bff;
  color: white;
}
.react-calendar__tile.unselected {
  background: $white-color !important;
  color: inherit;
}

.WelcomeCart {
  background: linear-gradient(223deg, #aee7ff -6.49%, #030ac0 106.4%);
  border-radius: 10px;
  position: relative;

  &__body {
    padding: 20px;
  }

  &__footer {
    background: linear-gradient(
      155deg,
      #aee7ff -22.26%,
      rgba(3, 10, 192, 0) 55.13%
    );
  }

  &__title {
    font-size: 28px;
    font-family: "Murecho-Black", sans-serif;
    text-transform: uppercase;
    color: $white-color;
    margin-bottom: 5px;
  }

  &__subtitle {
    font-family: "Manrope-Bold", sans-serif;
    color: $white-color;
    margin-bottom: 20px;
  }

  &__steps {
    display: flex;
  }
  &__step {
    z-index: 3;
    border-radius: 25px;
    border: 0.5px dashed rgba(255, 255, 255, 0.2);
    width: fit-content;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    &-number {
      color: $white-color;
      font-family: "Manrope-Bold", sans-serif;
      width: 25px;
      height: 25px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-text {
      color: $white-color;
      font-family: "Manrope-Bold", sans-serif;
      margin: 0 10px;
    }
  }

  &__footer {
    border-radius: 0 0 10px 10px;
    //background: linear-gradient(
    //  105deg,
    //  #aee7ff -22.26%,
    //  rgba(3, 10, 192, 0) 55.13%
    //);
    padding: 20px;
  }

  &__button {
    width: fit-content;
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    border-radius: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 20px 5px 5px;

    &:hover {
      cursor: pointer;
      border: 0.5px solid rgba(255, 255, 255, 0.5);
    }

    &-round {
      background: $white-color;
      border-radius: 50%;
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      &--plus {
        width: 20px;
        height: 20px;
      }
    }

    &-text {
      color: $white-color;
      font-family: "Manrope-Bold", sans-serif;
      text-transform: uppercase;
    }
  }

  &__circle {
    position: absolute;
    top: 0;
    right: 20px;
    height: 100%;
  }

  &__analytics-container {
    margin-top: 20px;
  }
  &__spinner {
    display: inline-block;
    width: 14px;
    margin-left: 4px;
    padding: 2px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: $primary-font-color;
    --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: l3 1s infinite linear;
  }
  @keyframes l3 {
    to {
      transform: rotate(1turn);
    }
  }

}

@include tablet {
  .WelcomeCart {
    &__circle {
      display: none;
    }
  }
}
@include mobile {
  .WelcomeCart {
    &__circle {
      display: none;
    }

    &__steps {
      display: flex;
      flex-direction: column;
    }

    &__step {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}
