@use "src/style/main" as *;

.JobPayment {
  position: fixed; /* Position fixed to overlay content */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust position to center */
  border-radius: 12px; /* Rounded corners */
  background: $white-color; /* Background color */
  width: 450px; /* Modal width */
  padding: 20px; /* Padding inside modal */
  z-index: 1000; /* High z-index to overlay on top */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Box shadow for modal effect */

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 30px 0;
  }

  &__title {
    font-size: 20px;
    font-family: "Manrope-SemiBold", sans-serif;
  }

  &__close {
    width: 16px;
    height: 16px;

    &:hover {
      cursor: pointer;
    }
  }

  &__body {
    /* Add any specific styling for the body content */
  }

  &__loading {
    width: 100%;
    height: 620px;
  }

  &__noData {
    width: 100%;
    height: 620px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Manrope-Bold", sans-serif;
    color: $primary-font-color;
  }
}

@include mobile {
  .JobPayment {
    width: 360px;
    padding: 10px;
  }
}
