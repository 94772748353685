@use "src/style/main" as *;

.Checkbox {
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid rgba(19, 26, 40, 0.14);
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &__checkmark {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: linear-gradient(
      208deg,
      $secondary-color -9.13%,
      $primary-color 97.4%
    );
  }
}
