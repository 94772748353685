@use "src/style/main" as *;

.CurrentPlan {
  &__head {
    display: flex;
    align-items: center;
    border-radius: 25px;
    padding: 5px 15px 5px 5px;
    margin-right: 10px;
    border: 0.5px solid rgba(19, 35, 66, 0.3);

    &:hover {
      cursor: pointer;
    }

    &-active {
      background: linear-gradient(223deg, #aee7ff -6.49%, #030ac0 106.4%);
      border: none;
    }

    &-pro {
      background: linear-gradient(195deg, #c3e9f3 -17.97%, #2b9ab5 68.99%);
      border: none;
    }

    &-business-plus {
      background: linear-gradient(195deg, #cef5e9 -17.97%, #008C61 68.99%);
      border: none;
    }
    &-trial {
      background: linear-gradient(223deg, #aee7ff -6.49%, #030ac0 106.4%);
      border: none;
    }
  }

  &__image {
    width: 35px;
    height: 35px;
    background-color: $white-color;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: scale-down;

    &-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__subtext {
    font-size: 0.8em; /* Adjust the font size as needed */
    display: block; /* Ensures the text appears on a new line */
  }

  &__verified {
    position: absolute;
    bottom: -2px;
    right: 0;
    width: 23px;
    height: 23px;
  }

  &__plan {
    font-family: "Manrope-Bold", sans-serif;
    color: $primary-font-color;
    margin-top: 5px; /* Space above the text */
    font-size: 14px; /* Adjust font size */
    width: 100%; /* Ensure it spans across the row */
    text-align: center; /* Center-align the text */


    &-active {
      color: $white-color;
    }
  }

  &__package {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__package__name {
    font-family: "Manrope-Bold", sans-serif;
    color: $primary-color;
  }

  &__name {
    font-family: "Manrope-Bold", sans-serif;
    color: $primary-font-color;

    &-active {
      color: $primary-font-color;
    }
  }
  &__icon {
    width: 16px;
    height: 16px;
    margin-left: 10px;
  }
}

@include mobile {
  .CurrentPlan {
    &__image {
      margin-right: 5px;
    }
    &__name {
      display: none;
    }
    &__icon {
      margin-left: 5px;
    }
  }
}
