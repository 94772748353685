@use "src/style/main" as *;
@page {
  size: A4; /* Force the page size to A4 */
  margin: 20mm; /* Adjust margins as desired */
}

@media print {
  .CV {
    print-color-adjust: exact !important;
    &__experience,
    &__jobs,
    &__personal-details {
      height: auto !important;
      overflow: visible !important;
      page-break-inside: auto;
    }

    // Add some spacing between sections
    &__section-title {
      padding-top: 15px;
    }

    // Prevent single items from breaking across pages
    &__experience-item,
    &__personal-details-item {
      page-break-inside: avoid;
    }
  }

  // Fix parent containers
  .ApplicationBase {
    height: auto !important;

    &__form {
      height: auto !important;
    }
  }

  // Add page breaks between main sections
  .CV__container {
    page-break-after: avoid;
  }
  .CV__footer {
    display: none !important;
  }
}
.CV {
  position: relative;
  &__header {
    border-bottom: 0.2px solid rgba(220, 220, 220, 0.2);
    padding: 16px 20px 10px;
  }
  &__header-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  &__person-image {
    width: 55px;
    height: 55px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #1e2ecb;
    margin-right: 10px;
    &:hover {
      cursor: pointer;
    }
  }
  &__name-container {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  &__name {
    font-size: 18px;
    line-height: 20px;
    font-family: "Manrope-Bold", sans-serif;
    color: #122342;
    margin-right: 2px;
  }
  &__verified-icon {
    width: 30px;
    height: 30px;
  }
  &__favorite-button {
    //margin-left: 10px;
    margin-bottom: 10px;
    width: fit-content;
    color: #132342;
    font-family: "Manrope-Bold", sans-serif;
    font-size: 10px;
    line-height: 9.4px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    border: 1px solid $primary-color;
    border-radius: 4px;
    padding: 5px 8px 4px;
    cursor: pointer;
    display: flex;
    align-items: center; /* Center the text and icon vertically */
  }
  &__favorite-button-icon {
    margin-right: 8px; /* Add space between the icon and text */
    color: #FF6347; /* Change this color to your desired icon color */
    width: 10px;
  }
  &__review-wrapper {
    font-size: 12px;
    line-height: 13px;
    font-family: "Manrope-Bold", sans-serif;
    color: #122342;
    margin-left: 2px;
  }
  &__role {
    font-size: 14px;
    line-height: 15px;
    font-family: "Manrope-Bold", sans-serif;
    color: #122342;
  }
  &__header-stats {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 5px;
  }

  &__body-job-stat-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 97px;
  }
  &__stat-label {
    font-size: 10px;
    line-height: 11px;
    font-family: "Manrope-Regular", sans-serif;
    color: #cccccc;
  }
  &__stat-number {
    font-size: 12px;
    line-height: 13px;
    font-family: "Manrope-Bold", sans-serif;
    color: #132342;
  }
  &__stat-line {
    display: flex;
    margin-top: 2px;
    margin-bottom: 5px;
  }
  &__stat-line-one {
    width: 18px;
    height: 4.5px;
    border-radius: 2.5px;
    margin-right: 2px;
    background-color: #a9a9a9;
    &--red {
      background-color: #dd291e;
    }
    &--yellow {
      background-color: #ffb82e;
    }
    &--green {
      background-color: #03c02d;
    }
  }
  &__container {
    display: flex;
    justify-content: start;
    border-bottom: 0.3px solid rgba(200, 200, 200, 0.3);
    padding: 20px 20px 0 20px;
  }
  &__container-item {
    font-size: 14px;
    line-height: 13.16px;
    font-family: "Manrope-Bold", sans-serif;
    color: #b2b2b2;
    margin-right: 30px;
    padding-bottom: 10px;
    cursor: pointer;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1.2px;
      bottom: -1px;
      left: 0;
      background: linear-gradient(265.82deg, #aee6ff 44.76%, #030ac0 58.06%);
      transform-origin: bottom right;
      transition: transform 300ms ease-out;
      visibility: hidden;
      z-index: 2;
    }

    &--active {
      color: #132342;
      &::before {
        transform: scaleX(1);
        transform-origin: bottom left;
        visibility: visible;
      }
    }
  }
  &__footer {
    padding-left: 20px;
    padding-right: 20px;
  }
  &__accept-btn {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.8px;
  }
  &__experience,
  &__jobs,
  &__personal-details {
    padding: 0 20px;
    height: calc(100vh - 380px);
    overflow: auto;
  }
  &__experience-item {
    padding: 20px 0 15px;
    border-bottom: 0.2px solid rgba(220, 220, 220, 0.2);
    p {
      font-size: 14px;
      line-height: 13px;
      font-family: "Manrope-Medium", sans-serif;
      color: #b2b2b2;
      margin-bottom: 8px;
    }
  }
  &__experience-item-title {
    font-size: 16px;
    line-height: 15px;
    font-family: "Manrope-Bold", sans-serif;
    color: #132342;
    margin-bottom: 8px;
  }
  &__no-experience-item-title {
    font-size: 14px;
    line-height: 15px;
    font-family: "Manrope-Medium", sans-serif;
    color: #b2b2b2;
    margin-bottom: 8px;
  }
  &__experience-item-date {
    font-size: 14px;
    line-height: 13px;
    font-family: "Manrope-Medium", sans-serif;
    color: #b2b2b2;
    margin-bottom: 8px;
  }
  &__personal-details {
    padding-top: 20px;
  }
  &__personal-details-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  &__personal-details-label {
    font-size: 12px;
    line-height: 11px;
    font-family: "Manrope-Bold", sans-serif;
    color: #c6c6c6;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  &__personal-details-value {
    font-size: 14px;
    line-height: 19px;
    font-family: "Manrope-Bold", sans-serif;
    color: #122342;
  }
  &__jobs-buttons-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &__job-filter-btn {
    height: 42px;
    border: 0.2px solid rgba(19, 35, 66, 0.2);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 4px 0px;
    border-radius: 21px;
    font-size: 14px;
    line-height: 13px;
    font-family: "Manrope-Bold", sans-serif;
    color: #132342;
    text-align: center;
    padding: 15px 12px;
    margin-right: 10px;
    &--active {
      background: linear-gradient(222.56deg, #aee7ff -6.49%, #030ac0 106.4%);
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 4px 0px;
      color: #ffffff;
      border: none;
      height: 42px;
      border-radius: 21px;
      font-size: 14px;
      line-height: 13px;
      font-family: "Manrope-Bold", sans-serif;
      text-align: center;
      padding: 15px 12px;
      margin-right: 10px;
    }
    &:hover {
      cursor: pointer;
      box-shadow: 0px 0px 20px 4px #cecece40;
    }
    span {
      font-size: 10px;
    }
  }
  &__jobs-review-item {
    border: 0.1px solid rgba(19, 35, 66, 0.1);
    box-shadow: 0px 0px 20px 4px #cecece40;
    border-radius: 21px;
    padding: 10px 20px;
    margin-bottom: 10px;
  }
  &__jobs-review-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  &__jobs-review-header-image {
    width: 37px;
    height: 37px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #1e2ecb;
    margin-right: 10px;
  }
  &__jobs-review-header-name {
    font-size: 14px;
    line-height: 15px;
    font-family: "Manrope-Bold", sans-serif;
    color: #132342;
  }
  &__jobs-review-body-text {
    font-size: 12px;
    line-height: 17px;
    font-family: "Manrope-SemiBold", sans-serif;
    color: #132342;
    margin-right: 10px;
  }
  &__jobs-review-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
  }
  &__jobs-review-body {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  &__jobs-review-body-read-more {
    font-size: 11px;
    color: #b2b2b2;
    font-family: "Manrope-SemiBold", sans-serif;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__arrow-icon {
    margin-top: 2.5px;
    width: 6px;
    rotate: -90deg;
    margin-left: 5px;
  }

  &__section-title {
    font-size: 18px;
    line-height: 18px;
    font-family: "Manrope-Bold", sans-serif;
    color: #122342;
    padding: 10px 0 10px;
    border-top: 1px solid rgba(220, 220, 220, 1);
  }
  &__section-title_icon {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  @include mobile {
    &__experience,
    &__jobs,
    &__personal-details {
      height: auto !important; // Let content determine height
      min-height: 50vh; // Ensure minimum visible area
      overflow: visible !important;
      padding-bottom: 20px;
    }

    &__footer {
      position: static; // Remove fixed positioning
      box-shadow: none; // Remove shadow
      padding: 16px 20px;
      background: transparent;
    }
  }
}
@include screen-fhd {
  .CV {
    &__experience,
    &__jobs,
    &__personal-details {
      height: calc(100vh - 380px);
      overflow: auto;
    }
  }
}

@include screen-2k {
  .CV {
    &__experience,
    &__jobs,
    &__personal-details {
      height: calc(100vh - 380px);
      overflow: auto;
    }
  }
}

@include screen-4k {
  .CV {
    &__experience,
    &__jobs,
    &__personal-details {
      height: calc(100vh - 380px);
      overflow: auto;
    }
  }
}

@include mobile {
  .CV {
    &__header-stats {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 15px;
      justify-content: center;
      align-items: center;
    }

    &__experience,
    &__jobs,
    &__personal-details {
      height: calc(100vh - 500px);
    }
  }
}
