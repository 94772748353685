@use "src/style/main" as *;

.ConfirmJobPaymentModal {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto; /* Allow the entire modal to scroll */
  &__container {
    width: fit-content;
    border-radius: 23px;
    background-color: $white-color;
    stroke-width: 0.1px;
    stroke: rgba(19, 35, 66, 0.39);
    filter: drop-shadow(0px 0px 20px rgba(206, 206, 206, 0.25));
    padding: 40px;
    position: relative;
    max-height: 90vh; /* Prevents modal from going over the screen height */
    max-width: 90vh;
    overflow-y: auto; /* Only the modal container is scrollable */
  }
  &__close {
    position: absolute;
    right: 25px;
    top: 25px;
    &:hover {
      cursor: pointer;
    }
  }
  &__label {
    font-family: "Manrope-Bold", sans-serif;
    color: $primary-font-color;
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
    line-height: 24px;
  }

  &__icon {
    display: block;
    margin: 20px auto 20px;
    width: 70px;
    height: 82px;
    object-fit: contain;
  }

  &__products__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;

    @media (max-width: 768px) {
      flex-direction: column; /* Switch to column layout */
      justify-content: center; /* Center items vertically */
      align-items: center; /* Align items horizontally */
      gap: 20px; /* Adjust gap between items */
    }
  }

  &__single__products__container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
  }

  @media (max-width: 768px) {
    &__container {
      padding: 20px; /* Reduce padding on small screens */
      max-width: 80%; /* Use a higher width percentage */
      max-height: 80vh; /* Ensure modal height remains constrained */
    }

    &__icon {
      width: 50px;
      height: 60px;
    }
  }

  @media (max-width: 480px) {
    &__container {
      padding: 15px; /* Further reduce padding */
      max-width: 90%; /* Use a higher width percentage */
    }
  }
}
