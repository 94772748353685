@use "src/style/main" as *;

.CreateCompany {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  &__left {
    width: 40%;
    height: 100%;
    background: $background-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    &-container {
      height: 100%;
      width: 100%;
      max-width: 510px;
      padding: 0 20px;
    }
  }

  &__right {
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: $secondary-font-color;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: darken($secondary-font-color, 10%);
    }

    &-mobile {
      width: 100%;
    }

    &-container {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 660px;
      padding: 0 20px;
    }

    &-head {
      height: 10%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-body {
      height: 90%;
      width: 100%;
    }

    &-logo {
      width: 200px;
    }

    &-title {
      font-family: "Murecho-Black", sans-serif;
      color: $primary-font-color;
      margin-top: 25px;
    }
  }

  &__logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 15%;

    &-icon {
      width: 200px;
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 85%;
    position: relative;
    z-index: 5;

    &-text {
      font-family: "Murecho-Black", sans-serif;
      font-size: 57px;
      text-align: left;
      color: $primary-font-color;
      line-height: 100%;
      letter-spacing: -1.71px;
    }
  }

  &__circle {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: flex-end;

    &-background {
      height: 70%;
      position: absolute;
      right: -200px;
    }

    &-icon {
      position: absolute;
      width: 160px;
      right: 35px;
      bottom: 70px;
    }
  }
}

@include screen-2k {
  .CreateCompany {
    &__circle {
      &-background {
        right: -320px;
      }
      &-icon {
        width: 200px;
        right: 70px;
        bottom: 100px;
      }
    }
  }
}

@include tablet {
  .CreateCompany {
    &__title {
      &-text {
        font-size: 45px;
      }
    }
  }
}

@include mobile {
  .CreateCompany {
    &__right {
      &-logo {
        width: 130px;
      }

      &-title {
        font-size: 14px;
        margin-top: 20px;
      }
    }
  }
}
