@use "src/style/main" as *;

.LanguageDropdown {
  position: relative;
  &__head {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white-color;
    padding: 8px;
    border-radius: 5px;
    border: 0.1px solid rgba(19, 35, 66, 0.1);
    filter: drop-shadow(0px 0px 20px rgba(206, 206, 206, 0.1));

    &:hover {
      cursor: pointer;
    }

    &-flag {
      width: 20px;
    }

    &-label {
      margin: 0 8px;
      font-size: 12px;
    }

    &-arrow {
      width: 10px;
      margin-right: 8px;
    }
  }
  &__body {
    background-color: $white-color;
    border-radius: 5px;
    position: absolute;
    width: 100%;
    top: 0;
    border: 0.1px solid rgba(19, 35, 66, 0.1);
    filter: drop-shadow(0px 0px 20px rgba(206, 206, 206, 0.1));

    &-item {
      padding: 8px;
      border-radius: 5px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &:hover {
        background-color: darken($white-color, 20%);
        cursor: pointer;
      }

      &--flag {
        width: 20px;
      }

      &--label {
        font-size: 12px;
        margin: 0 8px;
        text-align: left;
      }
    }
  }
}
