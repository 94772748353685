@use "src/style/main" as *;

.AuthPageResponse {
  height: 100%;
  display: flex;
  align-items: center;
  &__container {
    width: 100%;
  }

  &__title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
    width: 100%;

    &-icon {
      width: 80px;
    }

    &-text {
      font-family: "Manrope-Bold", sans-serif;
      color: $primary-font-color;
      text-align: left;
      margin-left: 20px;
    }
  }
}

@include screen-2k {
  .AuthPageResponse {
    &__container {
      margin-bottom: 30%;
    }
  }
}
