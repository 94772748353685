@use "src/style/main" as *;

.AttendanceProfile {
  &__info-card {
    border-bottom: 0.2px solid rgba(220, 220, 220, 0.2);
    padding: 16px 20px 20px;
    margin-bottom: 10px;
  }
  &__info-card-header {
    padding: 15px 10px;
    display: flex;
    align-items: center;
    border-bottom: 0.2px solid rgba(220, 220, 220, 0.2);
  }
  &__body {
    display: flex;
    justify-content: space-between;
  }
  &__person-image-wrapper {
    width: 58px;
    height: 58px;
    margin-right: 10px;
    border-radius: 50%;
  }
  &__person-image {
    width: 58px;
    height: 58px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #1e2ecb;
    &:hover {
      cursor: pointer;
    }
  }
  &__role {
    font-size: 18px;
    line-height: 19px;
    font-family: "Manrope-Bold", sans-serif;
    color: #132342;
    margin-bottom: 0;
  }
  &__basic-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
    padding: 20px;
    height: calc(100vh - 380px);
    overflow: auto;
  }
  &__basic-info-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  &__basic-info-label {
    font-size: 12px;
    line-height: 11px;
    font-family: "Manrope-Bold", sans-serif;
    color: #c6c6c6;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  &__basic-info-value {
    font-size: 14px;
    line-height: 19px;
    font-family: "Manrope-Bold", sans-serif;
    color: #122342;
  }

  &__att-card {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 10px;
    min-width: 311px;
    width: 100%;
    margin-top: 10px;
    height: calc(100vh - 380px);
    overflow: auto;
  }
  &__card-total {
    font-size: 14px;
    line-height: 1.55;
    font-family: "Manrope-Bold", sans-serif;
    color: #1fdef7;
    background-color: #131a28;
    box-shadow: 0px -1px 1px 0px #131a283b;
    text-transform: uppercase;
    padding: 0 5px;
    border-radius: 5px;
    text-transform: uppercase;
    width: fit-content;
    margin-left: 20px;
    span {
      margin-right: 2px;
    }
  }
  &__completed-icon {
    margin-left: 0;
    margin-right: 5px;
    width: 14.5px;
  }
  &__shift1 {
    font-size: 14px;
    display: flex;
    span {
      margin-left: 10px;
    }
  }
  &__shift-cards {
    margin-top: 10px;
  }
  &__review-card {
    padding: 20px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    border-radius: 10px;
  }
  &__review-text {
    color: $primary-font-color;
    font-size: 12px;
    line-height: 17px;
    font-family: "Manrope-SemiBold", sans-serif;
  }
  &__review-stats {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 20px;
  }
  &__body-job-stat-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 185px;
  }
  &__stat-label {
    font-size: 12px;
    line-height: 13px;
    font-family: "Manrope-Regular", sans-serif;
    color: #cccccc;
  }
  &__stat-number,
  &__review-card-rating {
    font-size: 12px;
    line-height: 13px;
    font-family: "Manrope-Bold", sans-serif;
    color: #132342;
  }
  &__stat-line {
    display: flex;
    margin-top: 6px;
    margin-bottom: 0;
  }
  &__stat-line-one {
    width: 36px;
    height: 6px;
    border-radius: 2.5px;
    margin-right: 2px;
    background-color: #a9a9a9;
    &--red {
      background-color: #dd291e;
    }
    &--yellow {
      background-color: #ffb82e;
    }
    &--green {
      background-color: #03c02d;
    }
  }
  &__review-card-rating {
    margin-bottom: 10px;
    display: flex;
  }
  &__review-stars {
    margin-right: 5px;
  }
  &__review-star {
    width: 13px;
  }
  &__review-star2 {
    width: 15.5px;
    margin-right: 3px;
    margin-bottom: -2px;
  }
  &__no-review {
    min-width: 310px;
    min-height: 146px;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__no-review-text {
    font-size: 14px;
    line-height: 15px;
    font-family: "Manrope-Bold", sans-serif;
    color: #132342;
    margin-bottom: 13px;
  }
  &__no-review-btn {
    min-width: 114px;
    height: 40px;
    border-radius: 7px;
    box-shadow: 0px 0px 20px 4px rgba(206, 206, 206, 0.25);
    border: 0.1px solid rgba(19, 35, 66, 0.2);
    font-size: 12px;
    line-height: 11px;
    font-family: "Manrope-Bold", sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.96px;
    color: $primary-font-color;
    background: #ffffff;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 0px 4px 2px rgba(206, 206, 206, 0.5);
    }
  }
  &__button,
  &__button-download {
    h4 {
      letter-spacing: 1.44px;
    }
  }
  &__button-wrapper {
    display: flex;
    padding: 20px;
  }
}

@include mobile {
  .AttendanceProfile {
    &__body {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 20px;
    }
    &__att-card {
      height: calc(100vh - 500px);
    }

    &__basic-info {
      flex-wrap: wrap;
      margin-right: 0;
      height: calc(100vh - 500px);
    }
    &__review-stats {
      grid-template-columns: 1fr;
      justify-content: center;
    }
    &__no-review {
      min-width: 260px;
      padding: 20px;
    }
    &__button,
    &__button-download {
      h4 {
        letter-spacing: 1.22px;
        font-size: 16px;
      }
    }
  }
}
