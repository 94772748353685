@use "src/style/main" as *;

.FourDigits {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  &__input {
    height: 71px;
    width: 100%;
    border-radius: 10px;
    color: $primary-font-color;
    outline: none;
    text-align: center;
    background: $white-color;
    border: 0.1px solid rgba(19, 35, 66, 0.1);
    filter: drop-shadow(0px 0px 20px rgba(206, 206, 206, 0.1));
    font-family: Manrope-Bold, sans-serif;
  }
}
