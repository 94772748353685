@use "src/style/main" as *;

.CompanySettingsContainer {
  background: $white-color;
  border-radius: 10px;
  border: 0.5px solid rgba(19, 35, 66, 0.1);
  box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);

  &__head {
    padding: 20px;
    border-bottom: 0.5px solid rgba(19, 35, 66, 0.1);
    &-title {
      font-family: "Manrope-Bold", sans-serif;
      font-size: 20px;
      color: $primary-font-color;
    }
  }

  &__body {
    padding: 20px;
  }
}

.CompanySettings {
  &__noData {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Manrope-Bold", sans-serif;
    color: $primary-font-color;
  }

  &__loader {
    height: 300px;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  &__title {
    font-family: "Manrope-Bold", sans-serif;
    font-size: 18px;
    color: $primary-font-color;
  }

  &__buttons {
    display: flex;
    font-family: "Manrope-Bold", sans-serif;
    color: $primary-font-color;
  }

  &__certificate {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    border: 0.5px solid rgba(19, 35, 66, 0.1);
    box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
    padding: 10px 20px;
    margin-right: 10px;
    &-text {
      margin-right: 10px;
      text-transform: uppercase;
    }
    &-icon {
      width: 15px;
      height: 15px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__edit {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    border: 0.5px solid rgba(19, 35, 66, 0.1);
    box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
    padding: 10px 20px;
    text-transform: uppercase;

    &:hover {
      cursor: pointer;
    }
  }

  &__body {
  }

  &__form {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 15px;
  }

  &__logo {
    &-title {
      font-family: "Manrope-Bold", sans-serif;
      text-transform: uppercase;
      color: $secondary-font-color;
      letter-spacing: 0.96px;
      padding-bottom: 10px;
    }
    &-image {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: 1px solid $secondary-font-color;
      object-fit: cover;
    }
  }

  &__details {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 15px;
  }

  &__detail {
    &-title {
      font-family: "Manrope-Bold", sans-serif;
      text-transform: uppercase;
      color: $secondary-font-color;
      letter-spacing: 0.96px;
      padding-bottom: 5px;
    }
    &-value {
      width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Manrope-Bold", sans-serif;
      color: $primary-font-color;
    }
  }
}

@include mobile {
  .CompanySettings {
    &__form {
      grid-template-columns: 1fr;
    }

    &__head {
      flex-direction: column;
    }

    &__title {
      margin-bottom: 20px;
    }

    &__buttons {
      justify-content: space-between;
    }

    &__details {
      grid-template-columns: 1fr;
      grid-gap: 25px;
    }
  }
}
