@use "src/style/main" as *;

.NewUnitModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  background: $white-color;
  width: 90%; /* Set width as a percentage for better responsiveness */
  max-width: 1200px; /* Restrict maximum width */
  padding: 20px;
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

  &__container {
    width: fit-content;
    max-width: 400px;
    border-radius: 23px;
    background-color: $white-color;
    stroke-width: 0.1px;
    stroke: rgba(19, 35, 66, 0.39);
    filter: drop-shadow(0px 0px 20px rgba(206, 206, 206, 0.25));
    padding: 20px;
    position: relative;
    max-height: 90vh;
  }
  &__content {
    max-height: 80vh;
    overflow-y: auto;
    padding: 20px;
  }

  &__close {
    position: absolute;
    right: 25px;
    top: 25px;
    &:hover {
      cursor: pointer;
    }
  }

  &__label {
    font-family: "Manrope-Bold", sans-serif;
    color: $primary-font-color;
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
    line-height: 24px;
  }

  &__inputs {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Default: side by side */
    grid-column-gap: 20px; /* Space between inputs */
    margin-bottom: 20px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr; /* Stack inputs vertically */
    }
  }

  &__scrollableContent {
    max-height: 50vh;
    overflow-y: auto;
    margin-bottom: 20px;
    padding-right: 10px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;

    &-button {
      max-width: 250px;

      @media (max-width: 768px) {
        max-width: 100%; /* Make button full width on small screens */
      }
    }
  }

  @media (max-width: 768px) {
    width: 90%;
    max-height: 80vh;

    &__container {
      padding: 20px;
      max-width: 90%;
    }
  }

  @media (max-width: 480px) {
    padding: 15px;
    max-height: 75vh;

    &__container {
      max-width: 90%;
    }
  }
}

.SchedulePlanButton {
  &__body {
    padding: 20px;
  }
  &__paragraph {
    padding-top: 5px;
    font-size: 14px;
  }
}

@include mobile {
  .CreateUnit {
    &__inputs {
      grid-template-columns: 1fr;
    }

    &__footer {
      &-button {
        max-width: 100%;
      }
    }
  }
}
