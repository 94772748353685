@use "src/style/main" as *;

.ShortTermPicker {
  margin-bottom: 15px;
  &__dates {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    border-radius: 10px;
    background: $white-color;
    border: 0.1px solid rgba(19, 35, 66, 0.1);
    box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
    padding: 20px;
    margin-bottom: 15px;

    &--active {
      cursor: pointer;
    }
  }

  &__datesLabel {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &-icon {
      width: 18px;
      height: 18px;
    }
    &-text {
      font-size: 16px;
      color: $secondary-font-color;
      margin-left: 10px;
    }
  }

  &__date {
    background: rgba(74, 151, 255, 0.2);
    padding: 10px;
    border-radius: 8px;
    position: relative;
    &-container {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      &--active {
        margin-top: 20px;
      }
    }
    &-dateAndMonth {
    }
    &-day {
      font-size: 13px;
      font-family: "Manrope-Regular", sans-serif;
    }
    &-close {
      width: 20px;
      height: 20px;
      padding: 6px;
      position: absolute;
      top: 10px;
      right: 7.5px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__add {
    background: rgba(74, 151, 255, 0.2);
    padding: 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:hover {
      cursor: pointer;
      background: rgba(74, 151, 255, 0.3);
    }
    &-plus {
      width: 10px;
      height: 10px;
      margin-top: 4px;
      margin-bottom: 8px;
    }
    &-text {
      font-size: 13px;
      font-family: "Manrope-Regular", sans-serif;
      color: $primary-color;
    }
  }

  &__calendar {
    position: absolute;
    padding: 10px;
    z-index: 200;
  }

  &__times {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
  }

  &__errorMessage {
    text-align: right;
    margin: 5px 10px;
    font-family: "Manrope-Bold", sans-serif;
    color: $error-color;
  }
}

//REACT-CALENDAR

.react-calendar {
  border-radius: 10px;
  background: $white-color;
  border: 0.1px solid rgba(19, 35, 66, 0.1);
  box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
  padding: 10px;
}

.react-calendar__navigation button {
  font-size: 16px;
}

.react-calendar__tile {
  background: none;
  border-radius: 4px;
  transition: background 0.3s ease-in-out;
}

.react-calendar__tile--now {
  font-weight: bold;
}

.past-date {
  color: $secondary-font-color;
  background: $white-color !important;
}

@include mobile {
  .ShortTermPicker {
    &__calendar {
      width: 280px;
    }
  }
}
