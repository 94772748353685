@use "src/style/main" as *;

.VerifyPhoneNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  &__logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 15%;
  }
  &__left {
    width: 40%;
    height: 100%;
    background: $background-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    &-container {
      height: 100%;
      width: 100%;
      max-width: 510px;
      padding: 0 20px;
    }
  }
  &__right {
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &-mobile {
      width: 100%;
    }

    &-container {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 560px;
      padding: 0 20px;
    }

    &-head {
      height: 10%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-body {
      height: 90%;
      width: 100%;
    }

    &-title {
      font-family: "Manrope-Bold", sans-serif;
      font-size: 14px;
      text-transform: uppercase;
      margin-top: 20px;
      letter-spacing: 1.26px;
    }
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 85%;
    position: relative;
    z-index: 5;

    &-text {
      font-family: "Murecho-Black", sans-serif;
      font-size: 57px;
      text-align: left;
      color: $primary-font-color;
      text-transform: uppercase;
      line-height: 90.6%;
      letter-spacing: -1.71px;
    }
  }

  &__circle {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: flex-end;

    &-background {
      height: 70%;
      position: absolute;
      right: -200px;
    }

    &-icon {
      position: absolute;
      width: 160px;
      right: 35px;
      bottom: 70px;
    }
  }
}
