@use "src/style/main" as *;

.LongTermForm {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px 0;
  min-height: 680px;
  height: 100%;
  border: 0.5px solid rgba(19, 35, 66, 0.1);
  box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);

  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }
  &__header-wrapper {
    display: flex;
    align-items: center;
    padding: 20px;
  }
  &__default-title {
    font-family: "Manrope-Bold", sans-serif;
    font-size: 20px;
    color: $primary-font-color;
    padding: 0 20px 20px 20px;
    border-bottom: 0.5px solid rgba(19, 35, 66, 0.1);
  }
  &__title {
    font-size: 18px;
    line-height: 19px;
    font-family: "Manrope-Bold", sans-serif;
    color: #122342;
  }
  &__back-icon {
    width: 18px;
    height: 18px;
    rotate: 90deg;
    margin-right: 15px;

    &:hover {
      cursor: pointer;
    }
  }
  &__input-container {
    margin-bottom: 20px;
    position: relative;
  }
  &__input {
    box-shadow: 0px 0px 20px 4px #cecece40;
    &-hint {
      margin: 5px 10px 0 10px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      &-icon {
        width: 14px;
        height: 14px;
        margin-right: 3px;
      }
      &-label {
        font-size: 11px;
        color: $secondary-font-color;
        font-family: "Manrope-Medium", sans-serif;
      }
    }
  }
  &__input input {
    &::placeholder {
      color: #b2b2b2;
      font-family: "Manrope-Bold", sans-serif;
    }
  }
  &__form {
    display: grid;
    grid-template-columns: 47% 47%;
    grid-gap: 6%;
    padding: 0 20px 20px 20px;
  }
  &__dropdown input {
    &::placeholder {
      font-family: "Manrope-Bold", sans-serif;
      color: #b2b2b2;
    }
  }
  &__images-placeholder {
    width: 94px;
    height: 94px;
    border-radius: 10px;
    border: 0.1px solid rgba(19, 35, 66, 0.1);
    box-shadow: 0px 0px 20px 4px #cecece40;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 4px;
  }

  &__image-preview {
    width: 92px;
    height: 92px;
    object-fit: cover;
    border-radius: 6px;
    &:hover {
      cursor: pointer;
    }
  }
  &__add-icon {
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
  }
  &__add-icon {
    // background: linear-gradient(188.55deg, #aee7ff 6.65%, #030ac0 104.56%);
    stroke: linear-gradient(188.55deg, #aee7ff 6.65%, #030ac0 104.56%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &__image-label,
  &__shift-label,
  &__subtitle {
    font-family: "Manrope-Bold", sans-serif;
    color: #132342;
    margin-bottom: 20px;
  }
  &__images-label {
    font-size: 16px;
    line-height: 22px;
  }
  &__shift-label,
  &__subtitle {
    font-size: 20px;
    line-height: 19px;
  }
  &__seat-info {
    display: flex;
    align-items: center;
    padding: 5px 10px 0 10px;
  }
  &__upgrade-link {
    margin-left: 5px;
    text-decoration: none;
    background: linear-gradient(188.55deg, #aee7ff 6.65%, #030ac0 104.56%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Manrope-SemiBold", sans-serif;
    font-size: 12px;
    line-height: 15px;
    display: inline-block;

    &:hover {
      cursor: pointer;
    }
  }
  &__images-label {
    font-size: 16px;
    line-height: 22px;
    font-family: "Manrope-Bold", sans-serif;
    color: #132342;
    margin-right: 10px;
  }
  &__images-note {
    font-size: 11px;
    color: $secondary-font-color;
    font-family: "Manrope-Medium", sans-serif;
    margin-left: 2px;
  }
  &__info-icon {
    width: 16px;
    height: 16px;
  }

  &__button {
    width: 207px;
    margin-top: 40px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: 20px;
    letter-spacing: 0.08em;
  }
  &__images-label-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  &__textarea {
    min-height: 300px;
    box-shadow: 0px 0px 20px 4px #cecece40;
    &::placeholder {
      color: #b2b2b2;
    }
  }
  &__input-error {
    font-family: "Manrope-Bold", sans-serif;
    color: #dd291e;
    font-size: 11px;
    padding: 5px 10px 0 10px;
  }
  &__banner {
    display: flex;
    align-items: center;
    padding: 0px 20px;
    height: 67px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px 4px rgba(206, 206, 206, 0.25);
    border: 0.1px solid rgba(19, 35, 66, 0.1);
    margin-bottom: 20px;
    span {
      color: #132342;
      font-family: "Manrope-Bold", sans-serif;
      font-size: 16px;
    }
  }
  &__warning {
    width: 41px;
    margin-right: 20px;
  }
  &__input-container-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  &__image-preview-container {
    position: relative;
  }
  &__delete-icon {
    position: absolute;
    width: 40px;
    height: 40px;
    top: -6px;
    left: -10px;
    cursor: pointer;
  }
  &__images {
    margin-top: 20px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
  }
  &__shift-info-text {
    color: #b2b2b2;
    font-family: "Manrope-Medium", sans-serif;
    font-size: 12px;
    line-height: 15px;
  }

  &__jobType {
    padding-top: 20px;

    &-title {
      font-family: "Manrope-Bold", sans-serif;
      font-size: 20px;
      color: #122342;
      margin-bottom: 20px;
      margin-top: 20px;
      margin-right: 10px;
      &-container {
        display: flex;
        align-items: center;
      }
    }
    &-body {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__jobBenefits {
    padding-top: 20px;
    &-title {
      font-family: "Manrope-Bold", sans-serif;
      font-size: 20px;
      color: #122342;
      margin-bottom: 20px;
      margin-top: 20px;
      margin-right: 10px;

      &-container {
        display: flex;
        align-items: center;
      }
    }
    &-body {
      display: flex;
      flex-wrap: wrap;
    }

    &-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      font-size: 12px;
      text-decoration: underline;
    }
    &-viewLess {
      margin: 0 10px;
      &:hover {
        cursor: pointer;
      }
    }
    &-viewMore {
      margin: 0 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__errorMessage {
    text-align: right;
    margin: 5px 10px;
    font-family: "Manrope-Bold", sans-serif;
    color: $error-color;
  }
}

.JobTypeCart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 10px;
  background: $white-color;
  border: 0.1px solid rgba(19, 35, 66, 0.1);
  filter: drop-shadow(0px 0px 20px rgba(206, 206, 206, 0.1));
  padding: 10px 15px;
  margin: 5px;
  color: $secondary-font-color;
  &--active {
    background: rgba(74, 151, 255, 0.2);
    color: $primary-font-color;
  }
  &:hover {
    cursor: pointer;
  }
  &__label {
    font-family: "Manrope-Bold", sans-serif;
    font-size: 14px;
    margin-left: 10px;
  }
}

.JobBenefitsCart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 10px;
  background: $white-color;
  border: 0.1px solid rgba(19, 35, 66, 0.1);
  filter: drop-shadow(0px 0px 20px rgba(206, 206, 206, 0.1));
  padding: 10px 15px;
  margin: 5px;
  color: $secondary-font-color;
  &--active {
    background: rgba(74, 151, 255, 0.2);
    color: $primary-font-color;
  }
  &:hover {
    cursor: pointer;
  }
  &__icon {
    height: 15px;
  }
  &__label {
    font-family: "Manrope-Bold", sans-serif;
    font-size: 14px;
    margin-left: 10px;
  }
}

@include mobile {
  .LongTermForm {
    &__form {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 30px;
    }

    &__input-container-row {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }

    &__button {
      width: 90%;
      margin-top: 40px;
      margin-bottom: 10px;
    }
  }
}
