@use "src/style/main" as *;

.SubscribeForJobsModal {
  background: linear-gradient(223deg, #aee7ff -6.49%, #030ac0 106.4%);
  border-radius: 10px;
  position: relative;
  &__close {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 30px;
    top: 30px;
    &:hover {
      cursor: pointer;
    }
  }

  &__body {
    display: flex;
    align-items: center;
    padding: 50px;
    z-index: 10;
  }

  &__left {
  }

  &__center {
    align-self: center;
    padding-top: 30px;
    padding-left:30px;
  }

  &__right {
    padding-left:30px;
    padding-top: 30px;
  }

  &__title {
    font-family: "Murecho-Black", sans-serif;
    color: $white-color;
    font-size: 56px;
    letter-spacing: -1.68px;
    text-transform: uppercase;
    line-height: 100%;
    margin-bottom: 20px;
    margin-right: 30px;
  }
  &__description {
    color: $white-color;
    font-size: 25px;
    max-width: 391px;
    margin-bottom: 30px;
  }

  &__plus-basic {
    color: $white-color;
    align-self: center;
    text-align: center;
    font-size: 20px;
    max-width: 391px;
    margin-bottom: 30px;
  }

  &__button {
    width: fit-content;
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    border-radius: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 20px 5px 5px;
    z-index: 5;

    &:hover {
      cursor: pointer;
      border: 0.5px solid rgba(255, 255, 255, 0.5);
    }

    &-round {
      background: $white-color;
      border-radius: 50%;
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      &--plus {
        width: 30px;
        height: 30px;
      }
    }

    &-text {
      color: $white-color;
      font-family: "Manrope-Bold", sans-serif;
      white-space: nowrap;
      text-transform: uppercase;
    }
  }
}

.SubscribeForJobsModalCart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  position: relative;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $white-color;
    border-radius: 10px;
    box-shadow: 0 0 20px 4px rgba(0, 0, 0, 0.03);
    width: 250px;
    padding: 10px 30px 10px 10px;
  }
  &__price-container {
    display: inline;
    justify-content: center;
    align-items: center;
    color: #1c1b1f;
    background: $white-color;
    border-radius: 10px;
    box-shadow: 0 0 20px 4px rgba(0, 0, 0, 0.03);
    width: fit-content;
    padding: 10px 10px 10px 10px;
    font-size: 20px;
    //max-width: 391px;
  }

  &__header-pro {
    background: linear-gradient(195deg, #c3e9f3 -17.97%, #2b9ab5 68.99%);
    padding: 3px;
    text-align: center;
    font-size: 14px;
    color: $white-color;
    border-radius: 10px 10px 0 0; /* Top-left and top-right rounded corners */
  }
  &__header-basic {
    background: linear-gradient(223deg, #aee7ff -6.49%, #030ac0 106.4%);
    padding: 3px;
    text-align: center;
    font-size: 14px;
    color: $white-color;
    border-radius: 10px 10px 0 0; /* Top-left and top-right rounded corners */
  }

  &__icon {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }

  &__number {
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -10px;
    top: -20px;
    color: $primary-font-color;
    padding-right: 3px;
  }

  &__shadow {
    width: 92%;
    height: 20px;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(10px);
    border-radius: 0 0 10px 10px;
    box-shadow: 0 0 20px 4px rgba(0, 0, 0, 0.03);
  }
  &__shadowTwo {
    width: 85%;
    height: 15px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 0 0 10px 10px;
    box-shadow: 0 0 20px 4px rgba(0, 0, 0, 0.03);
  }
}

@include mobile {
  .SubscribeForJobsModal {
    &__body {
      flex-direction: column;
      //justify-content: center;
      max-height: 80vh; /* Ensure modal height remains constrained */
      overflow-y: auto;
    }

    &__close {
      right: 20px;
      top: 20px;
    }

    &__title {
      font-size: 36px;
    }

    &__description {
      font-size: 18px;
      max-width: 300px;
    }

    &__button {
      &-container {
        margin-top: 30px;
        display: flex;
        justify-content: center;
      }
    }

    &__center {
      padding-top: 0;
      padding-left: 20px;
      padding-bottom: 20px;
    }

    &__right {
      padding-top: 0;
    }
  }
}
