@use "src/style/main" as *;

.JobAnalytics {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px 0 40px;
  min-height: 680px;
  height: 100%;
  border: 0.5px solid rgba(19, 35, 66, 0.1);
  box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
  align-content: center;
  align-items: center;

  &__title {
    font-size: 28px;
    font-family: "Murecho-Black", sans-serif;
    text-transform: uppercase;
    color: $primary-font-color;
    margin-bottom: 5px;
    margin-left: 20px;
    margin-right: 10px;
    text-align: start;
  }

  &__subtitle {
    font-family: "Manrope-Bold", sans-serif;
    color: $primary-font-color;
    margin-bottom: 5px;
    margin-left: 20px;
    text-align: start;
  }

  &__hint-info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 20px;
  }

  &__hint-icon {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }

  &__hint-text {
    color: #b2b2b2;
    font-family: "Manrope-Medium", sans-serif;
    font-size: 12px;
    line-height: 15px;
  }

  &__subscribe-subtitle {
    font-family: "Manrope-Bold", sans-serif;
    color: $primary-font-color;
    margin-top: 20px;
    margin-left: 50px;
    margin-right: 50px;
    text-align: center;
    //width: 100%;
    border-radius: 14px;
    box-shadow: 0px 0px 20px 4px #cecece40;
    background-color: #ffffff;
    position: relative;
    padding: 10px;
    transition: all 300ms ease-in-out;
    border: 1px solid #c90a0a;
    align-self: center;
    cursor: pointer;
    &:hover {
      box-shadow:
              rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
              rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }
  }

  &__user-details-container {
    display: flex;
    justify-content: space-between;
    margin: 50px;
  }

  &__user-details-container-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 20px;
  }

  .JobAnalytics__chart-container {
    display: flex;
    width: auto;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-left: 50px;
    margin-right: 50px;
    &-blur {
      filter: blur(5px);
    }
  }

  &__cards-row-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    gap: 20px;
  }

  &__chart-title {
    font-family: "Manrope-Bold", sans-serif;
    color: $primary-font-color;
    //margin-top: 20px;
    //margin-left: 50px;
    //margin-right: 50px;
    text-align: center;
    align-self: center;
    align-content: center;
    width: 100%;
    position: relative;

  }


}