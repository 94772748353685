@use "src/style/main" as *;

.ApplicationCard {
  width: 100%;
  border-radius: 14px;
  box-shadow: 0px 0px 20px 4px #cecece40;
  background-color: #ffffff;
  position: relative;
  padding-bottom: 10px;
  transition: all 300ms ease-in-out;
  &:hover {
    box-shadow:
      rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
  &__header {
    padding: 15px 10px;
    display: flex;
    align-items: center;
    border-bottom: 0.2px solid rgba(220, 220, 220, 0.2);
    &--accepted {
      background: #c3ffd4;
      border-radius: 14px 14px 0px 0px;
    }
    &--favourite {
      background: #67decf;
      border-radius: 14px 14px 0px 0px;
    }
    &--cancelled {
      background: #ffc3c3;
      border-radius: 14px 14px 0px 0px;
    }
  }

  &__header-chips {
    display: flex;
    align-items: start;
    padding-top: 10px;
    padding-left: 20px;
    overflow-x: auto;
    scrollbar-width: none;
    white-space: nowrap;
    cursor: grab;
  }

  &__person-image-wrapper {
    position: relative;
    width: 58px;
    height: 58px;
    margin-right: 10px;
    border-radius: 50%;
  }
  &__person-image {
    width: 58px;
    height: 58px;
    object-fit: cover;
    border-radius: 50%;
    border: 0.5px solid $black-color;
  }
  &__person-image-table {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 50%;
    border: 0.5px solid $black-color;
  }
  &__person-status-table {
    position: absolute;
    bottom: 0px;  // Adjust for desired spacing below the image
    left: 45%;
    transform: translateX(-50%);
    background-color: $success-color;  // Set the desired background color
    color: $white-color;
    padding: 2px 5px;
    border-radius: 4px;
    font-size: 10px;
    font-weight: bold;
  }

  &__person-status {
    position: absolute;
    bottom: -10px;  // Adjust for desired spacing below the image
    left: 50%;
    transform: translateX(-50%);
    background-color: $success-color;  // Set the desired background color
    color: $white-color;
    padding: 2px 5px;
    border-radius: 4px;
    font-size: 10px;
    font-weight: bold;
  }

  &__body {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  &__title_icon {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  &__title-history {
    font-size: 14px;
    line-height: 16px;
    font-family: "Manrope-Bold", sans-serif;
    color: #122342;
    padding: 10px 20px 0;
    //margin-bottom: 2px;
  }
  &__title_icon-history {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__title {
    font-size: 18px;
    line-height: 18px;
    font-family: "Manrope-Bold", sans-serif;
    color: #122342;
    padding: 10px 20px 0;
    //margin-bottom: 2px;
  }
  &__body-main-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 0 20px 0;
  }
  &__name {
    font-size: 18px;
    line-height: 18px;
    font-family: "Manrope-Bold", sans-serif;
    color: #122342;
    margin-bottom: 2px;
  }
  &__verified-icon {
    width: 30px;
    height: 30px;
  }
  &__favorite-button-icon {
    margin-left: 130px; /* Add space between the icon and text */
    color: #FF6347; /* Change this color to your desired icon color */
    cursor: pointer;
    width: 20px;
  }
  &__name-container {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__favorite-icon {
    //margin-left: auto;  // Pushes the favorite icon to the far right
    cursor: pointer;
  }

  &__role {
    font-size: 14px;
    line-height: 15px;
    font-family: "Manrope-Bold", sans-serif;
    color: #9c9c9c;
    margin-bottom: 6px;
  }
  &__review-wrapper {
    font-size: 12px;
    line-height: 6px;
    font-family: "Manrope-Bold", sans-serif;
    color: #122342;
  }
  &__review-wrapper--accepted {
    display: flex;
    align-items: center;
    //color: #ffffff;
    font-size: 14px;
    line-height: 15px;
    font-family: "Manrope-Bold", sans-serif;
    padding-bottom: 5px;
    span {
      margin-left: 5px;
    }
  }
  &__accepted-icon {
    width: 18.5px;
    height: 18.5px;
  }
  &__body-job-name {
    font-size: 12px;
    line-height: 13px;
    font-family: "Manrope-Regular", sans-serif;
    color: #cccccc;
    text-transform: uppercase;
    margin-bottom: 2px;
    word-break: break-word;
    white-space: pre-wrap;
    -moz-white-space: pre-wrap;
  }
  &__body-job-name-number {
    font-size: 17px;
    line-height: 16px;
    font-family: "Manrope-Bold", sans-serif;
    color: #132342;
  }
  &__button-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding-left: 20px;
    //padding-right: 20px;
  }

  &__button-row {
    display: flex;
    justify-content: end;
    gap: 10px;
  }
  &__back-icon-wrapper-table {
    width: fit-content;
    height: 40px;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.1px solid rgba(19, 35, 66, 0.1);
    box-shadow: 0px 0px 20px 4px rgba(206, 206, 206, 0.25);
    padding: 15px;
    //margin-left: auto;
    //margin-top: 10px;
    //margin-right: 10px;
    cursor: pointer;
    position: relative;
    span {
      font-size: 12px;
      line-height: 11px;
      font-family: "Manrope-Bold", sans-serif;
      color: #132342;
      letter-spacing: 0.8px;
      margin-right: 5px;
      text-align: center;
    }
    &:hover {
      cursor: pointer;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
  }


  &__back-icon-wrapper {
    width: fit-content;
    height: 40px;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.1px solid rgba(19, 35, 66, 0.1);
    box-shadow: 0px 0px 20px 4px rgba(206, 206, 206, 0.25);
    padding: 15px;
    //margin-left: auto;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
    position: relative;
    span {
      font-size: 12px;
      line-height: 11px;
      font-family: "Manrope-Bold", sans-serif;
      color: #132342;
      letter-spacing: 0.8px;
      margin-right: 5px;
      text-align: center;
    }
    &:hover {
      cursor: pointer;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
  }
  &__back-icon {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 12px;
    height: 12px;
    cursor: pointer;
    rotate: 180deg;
  }
  &__body-wrap {
    margin-bottom: 10px;
    width: 118px;
  }
  &__stat-label {
    font-size: 12px;
    line-height: 13px;
    font-family: "Manrope-Regular", sans-serif;
    color: #cccccc;
  }
  &__body-job-stat-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 118px;
  }
  &__stat-number {
    font-size: 12px;
    line-height: 13px;
    font-family: "Manrope-Bold", sans-serif;
    color: #132342;
  }
  &__stat-line {
    display: flex;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  &__stat-line-one {
    width: 22px;
    height: 5.5px;
    border-radius: 2.5px;
    margin-right: 2px;
    background-color: #a9a9a9;
    &--red {
      background-color: #dd291e;
    }
    &--yellow {
      background-color: #ffb82e;
    }
    &--green {
      background-color: #03c02d;
    }
  }
}
