@use "src/style/main" as *;

.EditLongTermJob {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px 0;
  min-height: 680px;
  height: 100%;
  border: 0.5px solid rgba(19, 35, 66, 0.1);
  box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);

  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }
  &__header-wrapper {
    display: flex;
    align-items: center;
    padding: 20px;
  }
  &__default-title {
    font-family: "Manrope-Bold", sans-serif;
    font-size: 20px;
    color: $primary-font-color;
    padding: 0 20px 20px 20px;
    border-bottom: 0.5px solid rgba(19, 35, 66, 0.1);
  }
  &__title {
    font-size: 18px;
    line-height: 19px;
    font-family: "Manrope-Bold", sans-serif;
    color: #122342;
  }
  &__back-icon {
    width: 18px;
    height: 18px;
    rotate: 90deg;
    margin-right: 15px;

    &:hover {
      cursor: pointer;
    }
  }
  &__input-container {
    margin-bottom: 20px;
    position: relative;
  }
  &__input {
    box-shadow: 0px 0px 20px 4px #cecece40;
    &-hint {
      margin: 5px 10px 0 10px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      &-icon {
        width: 14px;
        height: 14px;
        margin-right: 3px;
      }
      &-label {
        font-size: 11px;
        color: $secondary-font-color;
        font-family: "Manrope-Medium", sans-serif;
      }
    }
  }
  &__input input {
    &::placeholder {
      color: #b2b2b2;
      font-family: "Manrope-Bold", sans-serif;
    }
  }
  &__form {
    display: grid;
    grid-template-columns: 47% 47%;
    grid-gap: 6%;
    padding: 0 20px 20px 20px;
  }
  &__dropdown input {
    &::placeholder {
      font-family: "Manrope-Bold", sans-serif;
      color: #b2b2b2;
    }
  }
  &__images-placeholder {
    width: 94px;
    height: 94px;
    border-radius: 10px;
    border: 0.1px solid rgba(19, 35, 66, 0.1);
    box-shadow: 0px 0px 20px 4px #cecece40;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 4px;
  }

  &__image-preview {
    width: 92px;
    height: 92px;
    object-fit: cover;
    border-radius: 6px;
    &:hover {
      cursor: pointer;
    }
  }
  &__add-icon {
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
  }
  &__add-icon {
    // background: linear-gradient(188.55deg, #aee7ff 6.65%, #030ac0 104.56%);
    stroke: linear-gradient(188.55deg, #aee7ff 6.65%, #030ac0 104.56%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &__image-label,
  &__shift-label,
  &__subtitle {
    font-family: "Manrope-Bold", sans-serif;
    color: #132342;
    margin-bottom: 20px;
  }
  &__images-label {
    font-size: 16px;
    line-height: 22px;
  }
  &__shift-label,
  &__subtitle {
    font-size: 20px;
    line-height: 19px;
  }
  &__seat-info {
    display: flex;
    align-items: center;
    padding: 5px 10px 0 10px;
  }
  &__upgrade-link {
    margin-left: 5px;
    text-decoration: none;
    background: linear-gradient(188.55deg, #aee7ff 6.65%, #030ac0 104.56%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Manrope-SemiBold", sans-serif;
    font-size: 12px;
    line-height: 15px;
    display: inline-block;

    &:hover {
      cursor: pointer;
    }
  }
  &__images-label {
    font-size: 16px;
    line-height: 22px;
    font-family: "Manrope-Bold", sans-serif;
    color: #132342;
    margin-right: 10px;
  }
  &__images-note {
    font-size: 11px;
    color: $secondary-font-color;
    font-family: "Manrope-Medium", sans-serif;
    margin-left: 2px;
  }
  &__info-icon {
    width: 16px;
    height: 16px;
  }
  &__images-label-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  &__textarea {
    min-height: 300px;
    box-shadow: 0px 0px 20px 4px #cecece40;
    &::placeholder {
      color: #b2b2b2;
    }
  }
  &__input-error {
    font-family: "Manrope-Bold", sans-serif;
    color: #dd291e;
    font-size: 11px;
    padding: 5px 10px 0 10px;
  }
  &__banner {
    display: flex;
    align-items: center;
    padding: 0px 20px;
    height: 67px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px 4px rgba(206, 206, 206, 0.25);
    border: 0.1px solid rgba(19, 35, 66, 0.1);
    margin-bottom: 20px;
    span {
      color: #132342;
      font-family: "Manrope-Bold", sans-serif;
      font-size: 16px;
    }
  }
  &__warning {
    width: 41px;
    margin-right: 20px;
  }
  &__input-container-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  &__image-preview-container {
    position: relative;
  }
  &__delete-icon {
    position: absolute;
    width: 40px;
    height: 40px;
    top: -6px;
    left: -10px;
    cursor: pointer;
  }
  &__images {
    margin-top: 20px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
  }
  &__shift-info-text {
    color: #b2b2b2;
    font-family: "Manrope-Medium", sans-serif;
    font-size: 12px;
    line-height: 15px;
  }

  &__jobType {
    padding-top: 20px;

    &-title {
      font-family: "Manrope-Bold", sans-serif;
      font-size: 20px;
      color: #122342;
      margin-bottom: 20px;
      margin-top: 20px;
      margin-right: 10px;
      &-container {
        display: flex;
        align-items: center;
      }
    }
    &-body {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__jobBenefits {
    padding-top: 20px;
    &-title {
      font-family: "Manrope-Bold", sans-serif;
      font-size: 20px;
      color: #122342;
      margin-bottom: 20px;
      margin-top: 20px;
      margin-right: 10px;

      &-container {
        display: flex;
        align-items: center;
      }
    }
    &-body {
      display: flex;
      flex-wrap: wrap;
    }

    &-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      font-size: 12px;
      text-decoration: underline;
    }
    &-viewLess {
      margin: 0 10px;
      &:hover {
        cursor: pointer;
      }
    }
    &-viewMore {
      margin: 0 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__errorMessage {
    text-align: right;
    margin: 5px 10px;
    font-family: "Manrope-Bold", sans-serif;
    color: $error-color;
  }
  &__buttons-wrapper {
    display: flex;
    align-items: center;
    padding: 20px;
  }
  &__delete-button {
    justify-self: flex-start;
    margin-right: 10px;
    max-width: 195px;
  }
  &__buttons {
    max-width: 195px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
  }
  &__cancel-btn {
    background: #7e838b;
    margin-left: 10px;
    margin-right: 10px;
  }

  &__item-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__item {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
  }
  &__label {
    font-size: 12px;
    line-height: 11px;
    font-family: "Manrope-Bold", sans-serif;
    color: #c6c6c6;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
}

.JobTypeCart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 10px;
  background: $white-color;
  border: 0.1px solid rgba(19, 35, 66, 0.1);
  filter: drop-shadow(0px 0px 20px rgba(206, 206, 206, 0.1));
  padding: 10px 15px;
  margin: 5px;
  color: $secondary-font-color;
  &--active {
    background: rgba(74, 151, 255, 0.2);
    color: $primary-font-color;
  }
  &:hover {
    cursor: pointer;
  }
  &__label {
    font-family: "Manrope-Bold", sans-serif;
    font-size: 14px;
    margin-left: 10px;
  }
}

.JobBenefitsCart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 10px;
  background: $white-color;
  border: 0.1px solid rgba(19, 35, 66, 0.1);
  filter: drop-shadow(0px 0px 20px rgba(206, 206, 206, 0.1));
  padding: 10px 15px;
  margin: 5px;
  color: $secondary-font-color;
  &--active {
    background: rgba(74, 151, 255, 0.2);
    color: $primary-font-color;
  }
  &:hover {
    cursor: pointer;
  }
  &__icon {
    height: 15px;
  }
  &__label {
    font-family: "Manrope-Bold", sans-serif;
    font-size: 14px;
    margin-left: 10px;
  }
}

@include mobile {
  .EditLongTermJob {
    &__form {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 30px;
    }

    &__input-container-row {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }

    &__buttons-wrapper {
      flex-direction: column-reverse;
    }
    &__delete-button {
      margin-top: 20px;
      margin-right: 0;
      max-width: 440px;
    }
    &__buttons {
      flex-direction: column-reverse;
      max-width: 460px;
      width: 100%;
      display: flex;
    }
    &__cancel-btn {
      margin-top: 20px;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

//.EditLongTermJob {
//  background-color: #ffffff;
//  border-radius: 10px;
//  padding: 20px 0;
//  min-height: 680px;
//  height: 100%;
//  border: 0.5px solid rgba(19, 35, 66, 0.1);
//  box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
//  &__header {
//    display: flex;
//    flex-direction: column;
//  }
//  &__container {
//    display: flex;
//    border-bottom: 0.3px solid rgba(200, 200, 200, 0.3);
//    padding: 0 25px;
//  }
//  &__container-item {
//    font-size: 14px;
//    line-height: 13.16px;
//    font-family: "Manrope-Bold", sans-serif;
//    color: #b2b2b2;
//    margin-right: 30px;
//    padding-bottom: 10px;
//    cursor: pointer;
//    position: relative;
//    &::before {
//      content: "";
//      position: absolute;
//      width: 100%;
//      transform: scaleX(0);
//      height: 1.2px;
//      bottom: -1px;
//      left: 0;
//      background: linear-gradient(265.82deg, #aee6ff 44.76%, #030ac0 58.06%);
//      transform-origin: bottom right;
//      transition: transform 300ms ease-out;
//      visibility: hidden;
//      z-index: 2;
//    }
//
//    &--active {
//      color: #132342;
//      &::before {
//        transform: scaleX(1);
//        transform-origin: bottom left;
//        visibility: visible;
//      }
//    }
//  }
//  &__buttons-wrapper {
//    display: flex;
//    align-items: center;
//    padding: 20px;
//  }
//  &__buttons {
//    max-width: 400px;
//    width: 100%;
//    display: flex;
//    margin-left: auto;
//  }
//  &__cancel-btn {
//    background: #7e838b;
//    margin-left: 10px;
//    margin-right: 10px;
//  }
//  &__default-title {
//    font-family: "Manrope-Bold", sans-serif;
//    font-size: 20px;
//    color: $primary-font-color;
//    padding: 0 20px 20px 20px;
//  }
//  &__header-wrapper {
//    display: flex;
//    align-items: center;
//    padding: 25px 20px;
//  }
//  &__form {
//    margin-top: 25px;
//    padding: 0 20px;
//  }
//  &__delete-button {
//    justify-self: flex-start;
//    margin-right: 10px;
//    max-width: 195px;
//  }
//  &__item-flex {
//    display: flex;
//    align-items: center;
//    justify-content: space-between;
//  }
//  &__item {
//    display: flex;
//    flex-direction: column;
//    margin-bottom: 30px;
//  }
//  &__label {
//    font-size: 12px;
//    line-height: 11px;
//    font-family: "Manrope-Bold", sans-serif;
//    color: #c6c6c6;
//    letter-spacing: 0.8px;
//    text-transform: uppercase;
//    margin-bottom: 5px;
//  }
//  &__value {
//    font-size: 16px;
//    line-height: 21px;
//    font-family: "Manrope-Bold", sans-serif;
//    color: #122342;
//    cursor: not-allowed;
//  }
//  &__description {
//    font-size: 16px;
//    line-height: 22px;
//    font-family: "Manrope-Bold", sans-serif;
//    color: #122342;
//    max-width: 550px;
//    width: 100%;
//  }
//  &__images {
//    display: flex;
//    flex-wrap: wrap;
//    gap: 10px;
//  }
//  &__image {
//    width: 75px;
//    height: 75px;
//    object-fit: contain;
//    margin: 5px;
//    border-radius: 6px;
//  }
//
//  &__shift-container {
//    width: 263px;
//    height: 51px;
//    border-radius: 11px;
//    border: 1px solid #0000001a;
//    background-color: #fafbfb;
//    padding: 10px;
//    display: flex;
//    align-items: center;
//    justify-content: space-between;
//    margin-top: 10px;
//    cursor: not-allowed;
//  }
//  &__shift-item {
//    font-size: 14px;
//    line-height: 15px;
//    font-family: "Manrope-Bold", sans-serif;
//    color: #122342;
//  }
//  &__shift-item-small {
//    font-size: 12px;
//    line-height: 13px;
//    font-family: "Manrope-SemiBold", sans-serif;
//    color: #122342;
//  }
//  &__input-container {
//    position: relative;
//    max-width: 400px;
//    width: 100%;
//    cursor: pointer;
//  }
//  &__input {
//    box-shadow: 0px 0px 20px 4px #cecece40;
//  }
//
//  &__input-error {
//    font-family: "Manrope-Bold", sans-serif;
//    color: #dd291e;
//    font-size: 11px;
//    padding: 5px 10px 0 10px;
//  }
//  &__textarea {
//    min-height: 280px;
//    box-shadow: 0px 0px 20px 4px #cecece40;
//    &::placeholder {
//      color: $primary-font-color;
//      font-weight: 400;
//    }
//  }
//  &__textarea-desc {
//    padding: 10px 0;
//  }
//  &__images {
//    padding: 10px;
//    display: flex;
//    align-items: center;
//    flex-wrap: wrap;
//    gap: 10px;
//    max-width: 420px;
//  }
//  &__image-preview-container {
//    position: relative;
//    width: 90px;
//    height: 90px;
//  }
//  &__image-preview {
//    margin: 5px;
//    border-radius: 6px;
//    &:hover {
//      cursor: pointer;
//    }
//  }
//  &__delete-icon {
//    position: absolute;
//    width: 35px;
//    height: 35px;
//    top: 0;
//    left: -5px;
//    cursor: pointer;
//  }
//  &__images-placeholder {
//    width: 90px;
//    height: 91px;
//    border-radius: 6px;
//    border: 0.1px solid rgba(19, 35, 66, 0.1);
//    box-shadow: 0px 0px 20px 4px rgba(206, 206, 206, 0.2509803922);
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    cursor: pointer;
//    margin: 10px 5px 2px;
//  }
//  &__add-icon {
//    width: 15px;
//    height: 15px;
//    transform: rotate(45deg);
//  }
//  &__title {
//    font-size: 20px;
//    line-height: 19px;
//    font-family: "Manrope-Bold", sans-serif;
//    color: #122342;
//  }
//  &__back-icon {
//    width: 18px;
//    height: 18px;
//    rotate: 90deg;
//    margin-right: 15px;
//
//    &:hover {
//      cursor: pointer;
//    }
//  }
//  &__shift-container {
//    width: 263px;
//    height: 51px;
//    border-radius: 11px;
//    border: 1px solid #0000001a;
//    background-color: #fafbfb;
//    padding: 10px;
//    display: flex;
//    align-items: center;
//    justify-content: space-between;
//    margin-top: 10px;
//  }
//  &__shift-item {
//    font-size: 14px;
//    line-height: 15px;
//    font-family: "Manrope-Bold", sans-serif;
//    color: #122342;
//  }
//  &__shift-item-small {
//    font-size: 12px;
//    line-height: 13px;
//    font-family: "Manrope-SemiBold", sans-serif;
//    color: #122342;
//  }
//}
//
//@include mobile {
//  .EditLongTermJob {
//    &__buttons-wrapper {
//      flex-direction: column-reverse;
//    }
//    &__delete-button {
//      margin-top: 20px;
//      margin-right: 0;
//      max-width: 440px;
//    }
//    &__buttons {
//      flex-direction: column-reverse;
//      max-width: 460px;
//      width: 100%;
//      display: flex;
//    }
//    &__cancel-btn {
//      margin-top: 20px;
//      margin-left: 0;
//      margin-right: 0;
//    }
//  }
//}
