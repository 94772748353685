@use "src/style/main" as *;

.AddPaymentCard {
  border-radius: 12px;
  background: $white-color;
  width: 450px;
  padding: 20px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 30px 0;
  }

  &__title {
    font-size: 20px;
    font-family: "Manrope-SemiBold", sans-serif;
  }

  &__close {
    width: 16px;
    height: 16px;

    &:hover {
      cursor: pointer;
    }
  }

  &__body {
  }

  &__loading {
    width: 100%;
    height: 620px;
  }

  &__noData {
    width: 100%;
    height: 620px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Manrope-Bold", sans-serif;
    color: $primary-font-color;
  }
}

@include mobile {
  .AddPaymentCard {
    width: 360px;
    padding: 10px;
  }
}
