.userOverview {
    position: relative;
    line-height: 24px;
    font-weight: 500;
}
.heading {
    width: 372px;
    box-shadow: 0px -1px 0px #e5e7e8 inset;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    box-sizing: border-box;
}
.pageName {
    width: 148px;
    position: relative;
    line-height: 100%;
    text-transform: uppercase;
    font-weight: 500;
    display: inline-block;
    flex-shrink: 0;
}
.totalUsers {
    width: 80px;
    position: relative;
    line-height: 100%;
    text-transform: uppercase;
    font-weight: 500;
    display: inline-block;
    flex-shrink: 0;
}
.subHeading {
    background-color: #f5f6f7;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8px 24px;
    gap: 12px;
    opacity: 0.7;
    font-size: 10px;
    color: #4a5154;
}
.circle {
    width: 8px;
    position: relative;
    border-radius: 50%;
    background-color: #0e5fd9;
    height: 8px;
}
.requestAQuote {
    width: 132px;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    flex-shrink: 0;
}
.pageName1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}
.div {
    width: 80px;
    position: relative;
    line-height: 20px;
    color: #4a5154;
    display: inline-block;
    flex-shrink: 0;
}
.row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
}
.circle1 {
    width: 8px;
    position: relative;
    border-radius: 50%;
    background-color: #ff9500;
    height: 8px;
}
.circle2 {
    width: 8px;
    position: relative;
    border-radius: 50%;
    background-color: #e84646;
    height: 8px;
}
.tables {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    font-size: 14px;
}
.blurWrapper {
    filter: blur(10px);
    pointer-events: none;
    color: #6f6f73;
}

.mostVisitedPage {
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 0px 24px;
    box-sizing: border-box;
    gap: 24px;
    text-align: left;
    font-size: 16px;
    color: #191b1c;
    font-family: "Manrope-Bold", sans-serif;
    border: 1px solid #e6edff;

}

