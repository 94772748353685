@use "src/style/main" as *;

.SignUp {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  &__left {
    width: 50%;
    height: 100%;
    background: $background-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-mobile {
      width: 100%;
    }

    &-top {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      max-width: 560px;
      padding: 0 20px;
    }
  }
  &__right {
    width: 50%;
    height: 100%;
    background: linear-gradient(
      208deg,
      $secondary-color -9.13%,
      $primary-color 97.4%
    );
    display: flex;
    justify-content: center;

    &-container {
      z-index: 2;
      position: relative;
      height: 100%;
      width: 100%;
      max-width: 560px;
      padding: 0 20px;
    }
  }

  &__logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 15%;
    &-icon {
      width: 200px;
    }
  }

  &__form {
    height: 85%;
  }

  &__rounds {
    position: absolute;
    height: 100%;
    max-width: 50%;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  &__language {
    height: 15%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__tips {
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Manrope-Bold", sans-serif;
    color: $primary-font-color;
  }

  &__tipOne {
    background: $white-color;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    padding: 20px 50px 20px 30px;
    margin-bottom: 15px;
    margin-left: 100px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);

    &-icon {
      width: 50px;
      height: 50px;
    }
    &-text {
      font-size: 26px;
      padding-left: 30px;
      white-space: nowrap;
    }
  }
  &__tipTwo {
    background: $white-color;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    padding: 20px 50px 20px 30px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);

    &-icon {
      width: 50px;
      height: 50px;
    }
    &-text {
      font-size: 26px;
      padding-left: 30px;
      white-space: nowrap;
    }
  }
}

@include tablet {
  .SignUp {
    &__tipOne {
      margin-left: 0;

      &-icon {
        width: 30px;
        height: 30px;
      }
      &-text {
        font-size: 20px;
        padding-left: 10px;
      }
    }
    &__tipTwo {
      &-icon {
        width: 30px;
        height: 30px;
      }
      &-text {
        font-size: 20px;
        padding-left: 10px;
      }
    }
  }
}

@include mobile {
  .SignUp {
    &__logo {
      height: 10%;
    }
    &__form {
      height: 90%;
    }
  }
}
