.heroPricing {
    display: grid;
    grid-template-columns: 1fr repeat(3, 1fr);
    gap: 2rem;
    width: 100%;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    font-family: "Manrope", sans-serif;
}

.titleSection {
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    text-align: left;
}

.title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.subtitle {
    font-size: 16px;
    color: #858ba0;
}

.featureColumn {
    grid-column: 1 / 2;
    display: flex;
    flex-direction: column;
    padding-right: 1.5rem;
    border-right: 1px solid #e6e9f5;
    margin-top: 6.3rem;
}

.featureTitle {
    font-size: 14px;
    color: #252430;
    padding: 0.8rem 0;
    border-bottom: 1px solid #e6e9f5;
    height: 60px;
    display: flex;
    align-items: center;
}

.plans {
    display: grid;
    grid-column: 2 / 5;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.plan {
    display: flex;
    flex-direction: column;
    border: 1px solid #e6e9f5;
    border-radius: 8px;
    background-color: #f9f9f9;
    overflow: hidden;

    &:hover {
        cursor: pointer;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
        rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }
}

.planHeader {
    text-align: center;
    padding: 1rem;
    color: #ffffff;
}

.planPrice {
    font-size: 32px;
    font-weight: 700;
}

.planName {
    font-size: 18px;
    font-weight: 500;
}

.planFeature {
    border-bottom: 1px solid #e6e9f5;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.planFeature:last-child,
.featureTitle:last-child {
    border-bottom: none;
}

.checkIcon {
    width: 20px;
    height: 20px;
}

@media (max-width: 768px) {
    .heroPricing {
        grid-template-columns: 1fr; /* Single-column layout */
        grid-template-rows: auto 1fr;
        gap: 1.5rem;
    }

    .titleSection {
        text-align: center;
        margin-bottom: 2rem;
    }

    .featureColumn {
        display: none; /* Hide the feature column */
    }

    .plans {
        grid-column: 1 / 2;
        grid-template-columns: 1fr; /* Single column for plans */
    }

    .planFeature {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between; /* Distribute feature name and value */
        height: auto; /* Adjust height for better readability */
        padding: 1rem;
        border-bottom: 1px solid #e6e9f5;
    }

    .planFeature:last-child {
        border-bottom: none;
    }

    .featureTitleMobile {
        font-size: 14px;
        color: #252430;
        font-weight: 500;
        margin-right: 1rem; /* Add spacing between feature name and value */
    }

    .checkIcon {
        margin-left: auto; /* Align the check icon to the right */
    }
}