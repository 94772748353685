@use "src/style/main" as *;

.VerifyPhoneNumberModal {
  border-radius: 23px;
  background: $white-color;
  padding: 20px;
  width: 100%;
  max-width: 450px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &-container {
    }
  }
  &__title {
    font-size: 25px;
  }
  &__close {
    margin-top: 8px;
    &:hover {
      cursor: pointer;
    }
  }
}

.SendMessage {
  &__title {
    color: $secondary-font-color;
    margin-top: 10px;
  }

  &__icon {
    width: 100px;
    height: 100px;
    margin: 70px 0;
    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__inputs {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 10px;

    &-error {
      text-align: right;
      margin: 5px 10px;
      color: $error-color;
      height: 50px;
    }
  }
}

.GetMessage {
  &__title {
    color: $secondary-font-color;
    margin-top: 10px;
  }

  &__icon {
    width: 100px;
    height: 100px;
    margin: 70px 0;
    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &--effect {
      width: 50px;
      height: 50px;
      margin-top: 50px;
      margin-right: 10px;
      animation: getMessageEffect 0.8s forwards ease-in-out;
    }
    &--message {
      animation: getMessageEffectTwo 0.8s forwards ease-in-out;
      margin-right: 20px;
    }
  }
  &__inputs {
    &-error {
      text-align: right;
      margin: 5px 10px;
      color: $error-color;
      height: 50px;
    }
  }
}

@include mobile {
  .SendMessage {
    &__inputs {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@keyframes getMessageEffect {
  from {
    transform: translateX(-40px);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes getMessageEffectTwo {
  from {
    transform: translateX(-60px);
  }
  to {
    transform: translateX(0);
  }
}
