@use "src/style/main" as *;

.PaymentCardsContainer {
  background: $white-color;
  border-radius: 10px;
  border: 0.5px solid rgba(19, 35, 66, 0.1);
  box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);

  &__head {
    padding: 20px;
    border-bottom: 0.5px solid rgba(19, 35, 66, 0.1);
    &-title {
      font-family: "Manrope-Bold", sans-serif;
      font-size: 20px;
      color: $primary-font-color;
    }
  }

  &__body {
    padding: 20px;
  }
}

.PaymentCards {
  &__carts {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 15px;
  }

  &__noData {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Manrope-Bold", sans-serif;
    color: $primary-font-color;
  }

  &__loader {
    height: 300px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__title {
    font-family: "Manrope-Bold", sans-serif;
    font-size: 18px;
    color: $primary-font-color;
    display: flex;
    justify-content: center;
    align-items: center;

    &-back {
      width: 18px;
      height: 18px;
      rotate: 90deg;
      margin-right: 15px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.AddPaymentCardCart {
  background: linear-gradient(223deg, #aee7ff -6.49%, #030ac0 106.4%);
  border-radius: 10px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 30px;

  &__left {
    z-index: 5;
  }

  &__title {
    font-size: 28px;
    font-family: "Murecho-Black", sans-serif;
    text-transform: uppercase;
    color: $white-color;
    margin-bottom: 5px;
  }

  &__subtitle {
    font-family: "Manrope-Bold", sans-serif;
    color: $white-color;
  }

  &__button {
    width: fit-content;
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    border-radius: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 20px 5px 5px;
    z-index: 5;

    &:hover {
      cursor: pointer;
      border: 0.5px solid rgba(255, 255, 255, 0.5);
    }

    &-round {
      background: $white-color;
      border-radius: 50%;
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      &--plus {
        width: 20px;
        height: 20px;
      }
    }

    &-text {
      color: $white-color;
      font-family: "Manrope-Bold", sans-serif;
      white-space: nowrap;
      text-transform: uppercase;
    }
  }

  &__circle {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    max-width: 100%;
    z-index: 4;
    border-radius: 10px;
  }
}

@include mobile {
  .PaymentCards {
    &__carts {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
  }

  .AddPaymentCardCart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 10px;

    &__subtitle {
      margin-bottom: 20px;
    }

    &__button {
      margin-right: 0;
    }
  }
}
