@use "src/style/main" as *;

.SwitchButton {
  min-width: 55px;
  height: 30px;
  border-radius: 18px;
  border: 1px solid rgba(19, 26, 40, 0.14);
  padding: 0 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &--isChecked {
    justify-content: flex-end;
  }

  &:hover {
    cursor: pointer;
  }

  &__circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: linear-gradient(
      208deg,
      $white-color -9.13%,
      $secondary-font-color 97.4%
    );

    &--isChecked {
      background: linear-gradient(
        208deg,
        $secondary-color -9.13%,
        $primary-color 97.4%
      );
    }
  }
}
