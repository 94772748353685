@use "src/style/main" as *;

.DeleteModal {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  &__container {
    width: fit-content;
    max-width: 500px;
    width: 100%;
    border-radius: 23px;
    background-color: $white-color;
    stroke-width: 0.1px;
    stroke: rgba(19, 35, 66, 0.39);
    filter: drop-shadow(0px 0px 20px rgba(206, 206, 206, 0.25));
    padding: 40px;
    position: relative;
  }
  &__close {
    position: absolute;
    right: 25px;
    top: 25px;
    &:hover {
      cursor: pointer;
    }
  }
  &__label {
    font-family: "Manrope-Bold", sans-serif;
    color: #132342;
    font-size: 20px;
    color: $primary-font-color;
    text-align: center;
    margin-bottom: 50px;
    line-height: 24px;
  }

  &__icon {
    display: block;
    margin: 20px auto 40px;
    width: 70px;
    height: 82px;
    object-fit: contain;
  }

  &__buttons {
    display: flex;
    align-items: center;
  }
  &__cancel-btn h4 {
    letter-spacing: 0.8px;
  }
  &__submit-btn h4 {
    letter-spacing: 0.8px;
  }
 
}
