@use "src/style/main" as *;

.InputField {
  width: 100%;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 71px;
    border-radius: 10px;
    background: $white-color;
    border: 0.1px solid rgba(19, 35, 66, 0.1);
    filter: drop-shadow(0px 0px 20px rgba(206, 206, 206, 0.1));

    &--disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }

  &__input {
    width: 100%;
    font-size: 16px;
    padding: 0 20px;
    height: 71px;
    border-radius: 10px;
    color: $primary-font-color;
    outline: none;
    border: none;
    background: transparent;
    font-family: "Manrope-Bold", sans-serif;

    &--disabled {
      cursor: not-allowed;
    }

    &::placeholder {
      color: $secondary-font-color;
      font-weight: 400;
    }
  }

  &__icon {
    width: 20px;
    margin-right: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  &__leftIcon {
    width: 20px;
    margin-left: 20px;
  }

  &__icon-text {
    width: 20px;
    margin-right: 20px;
    color: $secondary-font-color;
    font-size: 16px;
    font-weight: 600;
    font-family: "Manrope-Bold", sans-serif;
  }
}
