@use "src/style/main" as *;

.CreateCompanyForm {
  height: 100%;

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 10%;
  }
  &__body {
    height: 90%;
  }

  &__description {
    color: $secondary-font-color;
    font-family: "Manrope-Bold", sans-serif;
    font-size: 20px;
    margin-bottom: 40px;
    text-align: center;
  }
}

.CompanyDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;

  &__body {
  }

  &__map {
    padding-top: 20px;
    width: 100%;
    height: 300px;

    &-error {
      text-align: right;
      margin: 5px 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $error-color;
    }
    &-description {
      text-align: left;
      margin: 5px 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $secondary-font-color;
      font-size: 14px;
    }
  }

  &__footer {
  }

  &__sectionOne {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 10px;
  }

  &__uploader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    &-error {
      text-align: right;
      margin: 5px 10px 30px 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $error-color;
    }
    &-description {
      text-align: center;
      margin: 5px 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $secondary-font-color;
      font-size: 12px;
      padding-bottom: 20px;
    }
  }

  &__circle {
    width: 87px;
    height: 87px;
    border-radius: 50%;
    position: relative;
    border: 1px solid #ebebeb;
  }

  &__placeholder {
    width: 87px;
    height: 87px;
    border-radius: 50%;
    object-fit: cover;
  }
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 87px;
    height: 87px;
    border-radius: 50%;
    object-fit: cover;
  }

  &__pen {
    position: absolute;
    bottom: -15px;
    right: -15px;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
  }

  &__dropdown-input {
    margin-bottom: 20px;
    flex-grow: 1;
    width: auto;
    border-radius: 10px;
    background: $white-color;
    border: 0.1px solid rgba(19, 35, 66, 0.1);
    filter: drop-shadow(0px 0px 20px rgba(206, 206, 206, 0.1));

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
    }
    &-error {
      text-align: right;
      margin: 5px 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $error-color;
    }
    &-description {
      text-align: left;
      margin: 5px 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $secondary-font-color;
      font-size: 12px;
    }
  }



  &__input {
    margin-bottom: 20px;
    flex-grow: 1;
    width: auto;
    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
    }
    &-error {
      text-align: right;
      margin: 5px 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $error-color;
    }
    &-description {
      text-align: left;
      margin: 5px 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $secondary-font-color;
      font-size: 12px;
    }
  }

  &__textArea {
    height: 130px;

    &-error {
      text-align: right;
      margin: 5px 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $error-color;
    }
  }

  &__button {
    margin: 30px 0;
  }
}

@keyframes tabBorderAnimation {
  from {
    width: 80%;
  }
  to {
    width: 100%;
  }
}

@include screen-2k {
  .CompanyDetails {
    &__map {
      height: 500px;
    }
  }
}
