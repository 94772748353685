@use "src/style/main" as *;

.AuthPage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  &__left {
    width: 50%;
    height: 100%;
    background: $background-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-mobile {
      width: 100%;
    }

    &-container {
      height: 100%;
      width: 100%;
      max-width: 560px;
      padding: 0 20px;
    }
  }

  &__right {
    width: 50%;
    height: 100%;
    background: radial-gradient(
      47.24% 45.55% at 56.95% 54.39%,
      #aee7ff 0%,
      #030ac0 100%
    );
    display: flex;
    justify-content: center;

    &-container {
      z-index: 2;
      position: relative;
      height: 100%;
      width: 100%;
      max-width: 520px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 15%;
    &-icon {
      width: 200px;
    }
  }

  &__form {
    height: 85%;
  }

  &__circle {
    width: 100%;
    height: 100%;
    max-width: 420px;
    max-height: 460px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-image: url("../../../src/assets/svg/small-circle-gradient.svg");
    background-repeat: no-repeat;
    background-position: center;
    transform: translateY(-70px);

    &-text {
      font-family: "Murecho-Black", sans-serif;
      font-size: 62px;
      line-height: 91%;
      color: $white-color;
      text-align: left;
      letter-spacing: -1.86px;
      margin: 0 0 20px 20px;
    }
  }

  &__spinner {
    width: 50px;
    padding: 5px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: $primary-font-color;
    --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: l3 1s infinite linear;

    &-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  @keyframes l3 {
    to {
      transform: rotate(1turn);
    }
  }
}

@include screen-2k {
  .AuthPage {
    &__spinner {
      margin-bottom: 30%;
    }
  }
}
