.div {
    position: relative;
    line-height: 150%;
    font-weight: 600;
}

.totalUsers {
    position: relative;
    font-size: 16px;
    line-height: 150%;
}

.text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.usersIcon {
    width: 24px;
    position: relative;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
}

.icon {
    box-shadow: 0px 2px 10px rgba(124, 141, 181, 0.12);
    border-radius: 12px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
}

.top {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.card {
    width: 198px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.divider {
    width: 1px;
    position: relative;
    border-right: 1px solid #e6edff;
    box-sizing: border-box;
    height: 103px;
}

.blurWrapper {
    filter: blur(10px);
    pointer-events: none;
    color: #6f6f73;
}

.cards {
    /*position: relative;*/
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #e6edff;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    gap: 38px;
    text-align: left;
    font-size: 28px;
    color: #000;
    font-family: "Manrope-Bold", sans-serif;
    align-content: center;
    align-self: center;
}

