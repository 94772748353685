@use "src/style/main" as *;

.NewJobForm {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px 0;
  min-height: 680px;
  height: 100%;
  border: 0.5px solid rgba(19, 35, 66, 0.1);
  box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);

  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  &__header-wrapper {
    display: flex;
    align-items: center;
    padding: 20px;
  }

  &__default-title {
    font-family: "Manrope-Bold", sans-serif;
    font-size: 20px;
    color: $primary-font-color;
    padding: 0 20px 20px 20px;
    border-bottom: 0.5px solid rgba(19, 35, 66, 0.1);
  }

  &__title {
    font-size: 18px;
    line-height: 19px;
    font-family: "Manrope-Bold", sans-serif;
    color: #122342;
  }

  &__back-icon {
    width: 18px;
    height: 18px;
    rotate: 90deg;
    margin-right: 15px;

    &:hover {
      cursor: pointer;
    }
  }

  &__input-container {
    margin-bottom: 20px;
    position: relative;
  }

  &__input {
    box-shadow: 0px 0px 20px 4px #cecece40;
  }

  &__input input {
    &::placeholder {
      color: #b2b2b2;
      font-family: "Manrope-Bold", sans-serif;
    }
  }

  &__form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    padding: 0 20px 20px 20px;
  }

  &__dropdown input {
    &::placeholder {
      font-family: "Manrope-Bold", sans-serif;
      color: #b2b2b2;
    }
  }

  &__images-placeholder {
    width: 94px;
    height: 94px;
    border-radius: 10px;
    border: 0.1px solid rgba(19, 35, 66, 0.1);
    box-shadow: 0px 0px 20px 4px #cecece40;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 4px;
  }

  &__image-preview {
    width: 92px;
    height: 92px;
    object-fit: cover;
    border-radius: 6px;

    &:hover {
      cursor: pointer;
    }
  }

  &__add-icon {
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
  }

  &__add-icon {
    // background: linear-gradient(188.55deg, #aee7ff 6.65%, #030ac0 104.56%);
    stroke: linear-gradient(188.55deg, #aee7ff 6.65%, #030ac0 104.56%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__image-label,
  &__shift-label,
  &__subtitle {
    font-family: "Manrope-Bold", sans-serif;
    color: #132342;
    margin-bottom: 20px;
  }

  &__images-label {
    font-size: 16px;
    line-height: 22px;
  }

  &__shift-label,
  &__subtitle {
    font-size: 20px;
    line-height: 19px;
  }

  &__seat-info {
    display: flex;
    align-items: center;
    padding: 5px 10px 0 10px;
  }

  &__upgrade-link {
    margin-left: 5px;
    text-decoration: none;
    background: linear-gradient(188.55deg, #aee7ff 6.65%, #030ac0 104.56%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Manrope-SemiBold", sans-serif;
    font-size: 12px;
    line-height: 15px;
    display: inline-block;

    &:hover {
      cursor: pointer;
    }
  }

  &__images-label {
    font-size: 16px;
    line-height: 22px;
    font-family: "Manrope-Bold", sans-serif;
    color: #132342;
    margin-right: 10px;
  }

  &__images-note {
    font-size: 11px;
    color: $secondary-font-color;
    font-family: "Manrope-Medium", sans-serif;
    margin-left: 2px;
  }

  &__info-icon {
    width: 16px;
    height: 16px;
  }

  &__button {
    width: 207px;
    margin-top: 40px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: 20px;
    letter-spacing: 0.08em;
  }

  &__images-label-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__textarea {
    min-height: 300px;
    box-shadow: 0px 0px 20px 4px #cecece40;

    &::placeholder {
      color: #b2b2b2;
    }
  }

  &__input-error {
    font-family: "Manrope-Bold", sans-serif;
    color: #dd291e;
    font-size: 11px;
    padding: 5px 10px 0 10px;
  }

  &__banner {
    display: flex;
    align-items: center;
    padding: 0px 20px;
    height: 67px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px 4px rgba(206, 206, 206, 0.25);
    border: 0.1px solid rgba(19, 35, 66, 0.1);
    margin-bottom: 20px;

    span {
      color: #132342;
      font-family: "Manrope-Bold", sans-serif;
      font-size: 16px;
    }
  }

  &__warning {
    width: 41px;
    margin-right: 20px;
  }

  &__input-container-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  &__image-preview-container {
    position: relative;
  }

  &__delete-icon {
    position: absolute;
    width: 40px;
    height: 40px;
    top: -6px;
    left: -10px;
    cursor: pointer;
  }

  &__images {
    margin-top: 20px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
  }

  &__shift-info-text {
    color: #b2b2b2;
    font-family: "Manrope-Medium", sans-serif;
    font-size: 12px;
    line-height: 15px;
  }
}

.SchedulePlanButton {
  border-radius: 10px;
  background: $white-color;
  border: 0.1px solid rgba(19, 35, 66, 0.1);
  box-shadow: 0px 0px 20px 4px #cecece40;
  transition: all 0.3s ease-in-out;
  padding: 10px;
  //border: 2px solid transparent;

  &:hover {
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.1px solid rgba(19, 35, 66, 0.1);
    padding: 20px;

    &-left {
      display: flex;
      align-items: center;
    }

    &-right {
      display: flex;
      align-items: center;
    }
  }

  &__description {
    color: $secondary-font-color;
    padding: 20px;
    padding-top: 0;
    font-size: 14px;
    //border-bottom: 0.1px solid rgba(19, 35, 66, 0.1);
  }

  &__price {
    font-size: 24px;
    margin-right: 8px; // Adjust the spacing between price and plus icon
    //text-decoration: line-through;
  }

  &__discount_price {
    font-size: 24px;
    margin-right: 8px; // Adjust the spacing between price and plus icon
    // strike through text
    text-decoration: line-through;
  }

  &__plus {
    width: 25px;
    height: 25px;
  }

  &__title {
    margin-left: 10px;
    font-size: 18px;

    & > span {
      font-size: 14px;
      color: $secondary-font-color;
    }
  }

  &__plus {
    width: 25px;
    height: 25px;
  }

  &__body {
    padding: 20px;
    //border-bottom: 0.1px solid rgba(19, 35, 66, 0.1);
  }

  &__paragraph {
    padding-top: 5px;
    //color: $secondary-font-color;
    font-size: 14px;
  }

   &__paragraph-included {
    padding-top: 5px;
    font-size: 14px;
    align-items: center;
    gap: 10px;
    display: flex;
    font-family: "Manrope-Bold", sans-serif;
    color: #122342;
    max-width: 250px;
  }

  &__paragraph-not-included {
    padding-top: 5px;
    font-size: 14px;
    align-items: center;
    gap: 10px;
    display: flex;
    font-family: "Manrope-Bold", sans-serif;
    color: $secondary-font-color;
    max-width: 250px;
  }

  &__price {
    padding: 10px;
    //color: $secondary-font-color;
    align-items: center;
    text-align: center;
    font-size: 34px;
  }
}

.SchedulePlanButton.selected {
  transform: scale(1.1);
  border: 2px solid #030ac0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.SchedulePlanButton__price_hint {
  background: linear-gradient(195deg, #c3e9f3 -17.97%, #2b9ab5 68.99%);
  padding: 10px;
  text-align: center;
  font-size: 14px;
  color: $primary-font-color;
  border-radius: 10px 10px 0 0; /* Top-left and top-right rounded corners */
}

.SchedulePlanButton__price_hint_pro {
  background: linear-gradient(195deg, #f8d1e1 -17.97%, #d94996 68.99%);
  padding: 10px;
  text-align: center;
  font-size: 14px;
  color: $primary-font-color;
  border-radius: 10px 10px 0 0; /* Top-left and top-right rounded corners */
}

.SchedulePlanButton__priceContainer {
  display: flex;
  justify-content: center; /* Center the whole price container */
  align-items: center; /* Align items vertically */
  gap: 16px; /* Adds space between new and old price */
  padding: 10px;
}

/* Styles for the new price */
.SchedulePlanButton__price--new {
  font-size: 34px; /* Larger size for emphasis */
  color: $primary-font-color;
}

/* Styles for the old price */
.SchedulePlanButton__price--old {
  font-size: 24px;
  text-decoration: line-through;
  color: $secondary-font-color;
}

.ScheduleCard {
  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 0;
  }

  &__chevron {
    rotate: 90deg;
    width: 25px;
    padding: 5px;
    margin-right: 5px;

    &:hover {
      cursor: pointer;
    }
  }
}

@include mobile {
  .NewJobForm {
    &__form {
      display: grid;
      grid-template-columns: 1fr;
      //grid-column-gap: 20px;
    }

    &__input-container-row {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }

    &__button {
      width: 90%;
      margin-top: 40px;
      margin-bottom: 10px;
    }
  }
}
