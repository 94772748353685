@use "src/style/main" as *;

.CommentModal {
  &__overlay {
    background: rgba(19, 35, 66, 0.09);
    backdrop-filter: blur(4.849999904632568px);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__container {
    border-radius: 23px;
    background-color: $white-color;
    box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.1);
    padding: 40px;
    position: relative;
    z-index: 51;
    overflow: auto;
  }
  &__close {
    width: 17px;
    &:hover {
      cursor: pointer;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  &__label {
    font-family: "Manrope-Bold", sans-serif;
    color: #132342;
    font-size: 20px;
    color: $primary-font-color;
    line-height: 19px;
  }

  &__icon {
    display: block;
    margin: 20px auto 40px;
    width: 70px;
    height: 82px;
    object-fit: contain;
  }

  &__submit-btn {
    margin-left: auto;
  }
  &__submit-btn h4 {
    letter-spacing: 1.44px;
    font-size: 18px;
  }
  &__body {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    margin-bottom: 48px;
  }
  &__body-col {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  &__stat-label {
    font-size: 18px;
    line-height: 17px;
    font-family: "Manrope-Bold", sans-serif;
    color: #132342;
    margin-bottom: 18px;
  }
  &__stat-body {
    display: flex;
    gap: 3px;
  }
  &__stat-number {
    width: 57px;
    height: 54px;
    font-size: 18px;
    line-height: 17px;
    font-family: "Manrope-Bold", sans-serif;
    border-radius: 6px;
    border: 1px solid #c3c3c3;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &--red {
      background-color: #dd291e;
      border: 1px solid #dd291e;
    }
    &--yellow {
      background-color: #ffb82e;
      border: 1px solid #ffb82e;
    }
    &--green {
      background-color: #03c02d;
      border: 1px solid #03c02d;
    }
  }
  &__text-area-container {
    margin-bottom: 40px;
  }
  &__text-area-label {
    color: #c6c6c6;
    font-family: "Manrope-Bold", sans-serif;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 0.96px;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-right: 100px;
  }
  &__textarea {
    height: 230px;
    filter: drop-shadow(0px 0px 20px rgba(206, 206, 206, 0.25));
    font-size: 14px;
    border: 1px solid $secondary-font-color;
    background: #fcf7f7;
  }
  &__error {
    font-family: "Manrope-Bold", sans-serif;
    font-size: 14px;
    line-height: 14px;
    color: #dd291e;
    margin-left: 5px;
  }
}

@include mobile {
  .CommentModal {
    &__overlay {
      align-items: unset;
      justify-content: unset;
    }
    &__container {
      padding: 30px;
    }
    &__body {
      flex-direction: column;
      gap: 20px;
    }
    &__container {
    }
    &__stat-number {
      width: 47px;
      height: 44px;
    }
    &__stat-label {
      margin-bottom: 10px;
      font-size: 16px;
    }
    &__body-col {
      gap: 20px;
    }

    &__submit-btn {
      margin-left: 0;
      width: 100% !important;
    }

    &__body {
      margin-bottom: 20px;
    }

    &__text-area-container {
      margin-bottom: 20px;
    }
  }
}
