@use "src/style/main" as *;

.MonthPicker {
  width: 100%;
  position: relative;
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    background: $white-color;
    border: 0.1px solid rgba(19, 35, 66, 0.1);
    box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
    height: 61px;
    padding: 0 20px;
    &:hover {
      cursor: pointer;
    }
    &-container {
    }
    &-label {
      color: $secondary-font-color;
      &--active {
        font-size: 10px;
        transition: all 0.3s ease-out;
      }
    }
    &-value {
    }
    &-chevron {
      width: 12px;
      height: 12px;
    }
  }

  &__body {
    position: absolute;
    width: 100%;
    z-index: 200;

    &-container {
      border-radius: 10px;
      background: $white-color;
      border: 0.1px solid rgba(19, 35, 66, 0.1);
      box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
      width: 100%;
      height: 100%;
      max-height: 300px;
      overflow-y: scroll;
      margin: 10px 0 20px 0;
    }

    &-item {
      padding: 10px 20px;
      &:hover {
        cursor: pointer;
        background: rgba(74, 151, 255, 0.2);
      }
    }
  }
}
