@use "src/style/main" as *;

.UnitCart {
  border-radius: 10px;
  border: 0.5px solid rgba(19, 35, 66, 0.1);
  background: #fff;
  box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid rgba(19, 35, 66, 0.1);
    padding: 15px;
  }
  &__organization {
    display: flex;
    justify-content: center;
    align-items: center;

    &-logo {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 5px;
      border: 0.5px solid rgba(19, 35, 66, 0.1);
    }
    &-text {
      color: $primary-font-color;
      margin-right: 5px;
    }
  }
  &__city {
    color: $primary-font-color;
    font-family: "Manrope-Bold", sans-serif;
    padding: 5px 10px;
    border: 1px solid $primary-color;
    border-radius: 4px;
    text-transform: uppercase;
  }

  &__body {
    padding: 15px;
  }

  &__location {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;

    &-icon {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }

    &-text {
      font-family: "Manrope-SemiBold", sans-serif;
    }
  }

  &__number {
    font-family: "Manrope-SemiBold", sans-serif;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px 15px 15px;
  }

  &__edit {
    padding: 10px 15px;
    border-radius: 7px;
    border: 0.5px solid rgba(19, 35, 66, 0.1);
    box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
    text-transform: uppercase;

    &:hover {
      cursor: pointer;
    }
  }
  &__default {
    color: $secondary-font-color;
  }
}
