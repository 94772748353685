@use "src/style/main" as *;

.EditUnitContainer {
  background: $white-color;
  border-radius: 10px;
  border: 0.5px solid rgba(19, 35, 66, 0.1);
  box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);

  &__head {
    padding: 20px;
    border-bottom: 0.5px solid rgba(19, 35, 66, 0.1);
    &-title {
      font-family: "Manrope-Bold", sans-serif;
      font-size: 20px;
      color: $primary-font-color;
    }
  }

  &__body {
    padding: 20px;
  }
}

.EditUnit {
  &__noData {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Manrope-Bold", sans-serif;
    color: $primary-font-color;
  }
  &__loader {
    height: 300px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__title {
    font-family: "Manrope-Bold", sans-serif;
    font-size: 18px;
    color: $primary-font-color;
    display: flex;
    justify-content: center;
    align-items: center;
    & > span {
      font-family: "Manrope-ExtraBold", sans-serif;
    }

    &-back {
      width: 18px;
      height: 18px;
      rotate: 90deg;
      margin-right: 15px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
  }

  &__input {
    margin-bottom: 20px;
    flex-grow: 1;
    &-error {
      text-align: right;
      margin: 5px 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $error-color;
    }
    &-description {
      text-align: left;
      margin: 5px 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $secondary-font-color;
      font-size: 12px;
    }
  }

  &__map {
    height: 300px;
    &-error {
      text-align: right;
      margin: 5px 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $error-color;
    }
    &-description {
      text-align: left;
      margin: 5px 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $secondary-font-color;
      font-size: 14px;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;

    &-button {
      max-width: 250px;
    }
  }
}

@include mobile {
  .EditUnit {
    &__inputs {
      grid-template-columns: 1fr;
    }

    &__footer {
      &-button {
        max-width: 100%;
      }
    }
  }
}
