@use "src/style/main" as *;

.Invoice {
  border-radius: 14px;
  border: 0.5px solid rgba(19, 35, 66, 0.1);
  background: $white-color;
  box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__left {
    display: flex;
    align-items: center;
  }
  &__right {
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: 20px;
    width: 26px;
    height: 32px;
  }

  &__details {
    margin-right: 20px;
    &-id {
      font-size: 14px;
    }
    &-date {
      font-size: 10px;
      color: $secondary-font-color;
      text-transform: uppercase;
    }
  }

  &__amount {
    font-size: 24px;
    color: $primary-font-color;
    font-family: "Manrope-Light", sans-serif;
    margin-right: 20px;
  }

  &__download {
    background: $primary-font-color;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }

  &__status {
    font-family: "Manrope-Bold", sans-serif;
    font-size: 11px;
    color: $white-color;
    text-transform: uppercase;
    border-radius: 3px;
    letter-spacing: 0.77px;
    padding: 2px 5px;
    background: linear-gradient(
      88deg,
      $primary-color 1.38%,
      $secondary-color 101.2%
    );

    &-paid {
      background: linear-gradient(88deg, #03c02d 1.38%, #8ef39b 101.2%);
    }
    //&-unpaid {
    //  background: linear-gradient(88deg, #dd291e 1.38%, #f3837a 101.2%);
    //}
    //&-pending {
    //  background: linear-gradient(88deg, #ffb82e 1.38%, #ffe08e 101.2%);
    //}
  }
}

@include mobile {
  .Invoice {
    display: flex;
    flex-direction: column;

    &__left {
      width: 100%;
      justify-content: space-between;
    }
    &__right {
      width: 100%;
      justify-content: space-between;
    }

    &__status {
      margin-right: 4px;
      margin-bottom: 11px;
    }
  }
}
