@use "src/style/main" as *;

.LongTermPicker {
  &__dates {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
    margin-bottom: 10px;
  }

  &__days {
    margin-bottom: 20px;

    &-label {
      margin-bottom: 10px;
    }
    &-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
      grid-gap: 10px;
    }
  }

  &__calendar {
    position: absolute;
    padding: 10px;
    z-index: 200;

    &-input {
      border-radius: 10px;
      background: $white-color;
      border: 0.1px solid rgba(19, 35, 66, 0.1);
      box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
      height: 61px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      &:hover {
        cursor: pointer;
      }
      &-placeholder {
        color: $secondary-font-color;
      }
    }

  }

  &__day {
    border-radius: 10px;
    background: $white-color;
    border: 0.1px solid rgba(19, 35, 66, 0.1);
    box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $secondary-font-color;

    &:hover {
      cursor: pointer;
    }

    &-active {
      background: rgba(74, 151, 255, 0.2);
      color: $primary-font-color;
    }

    &-weekend {
      color: lighten($error-color, 30%);
      &-active {
        color: $error-color;
      }
    }
  }

  &__times {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
  }

  &__errorMessage {
    text-align: right;
    margin: 5px 10px;
    font-size: 11px;
    font-family: "Manrope-Bold", sans-serif;
    color: $error-color;
  }

  &__hint {
    margin: 5px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &-icon {
      width: 14px;
      height: 14px;
      margin-right: 3px;
    }
    &-label {
      font-size: 11px;
      color: $secondary-font-color;
      font-family: "Manrope-Medium", sans-serif;
    }
  }
}
