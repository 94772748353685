@use "src/style/main" as *;

.SignIn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  &__left {
    width: 50%;
    height: 100%;
    background: $background-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-mobile {
      width: 100%;
    }

    &-top {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      max-width: 560px;
      padding: 0 20px;
    }
  }
  &__right {
    width: 50%;
    height: 100%;
    background: radial-gradient(
      47.24% 45.55% at 56.95% 54.39%,
      #aee7ff 0%,
      #030ac0 100%
    );
    display: flex;
    justify-content: center;

    &-container {
      z-index: 2;
      position: relative;
      height: 100%;
      width: 100%;
      max-width: 560px;
    }
  }

  &__logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 15%;
    &-icon {
      width: 200px;
    }
  }

  &__form {
    height: 85%;
  }

  &__language {
    height: 15%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px;
  }

  &__circle {
    width: 100%;
    height: 100%;
    max-width: 420px;
    max-height: 460px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-image: url("../../../src/assets/svg/small-circle-gradient.svg");
    background-repeat: no-repeat;
    background-position: center;
    transform: translateY(-100px);

    &-container {
      height: 85%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-text {
      font-family: "Murecho-Black", sans-serif;
      font-size: 62px;
      line-height: 91%;
      color: $white-color;
      text-align: left;
      letter-spacing: -1.86px;
      margin: 0 0 20px 20px;
    }

    &-tip {
      background: $white-color;
      font-family: "Manrope-Bold", sans-serif;
      color: $primary-font-color;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      padding: 20px 30px 20px 30px;
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
      position: absolute;
      bottom: 50px;
      right: 30px;

      &--icon {
        width: 50px;
        height: 50px;
      }
      &--text {
        font-size: 26px;
        padding-left: 30px;
        white-space: nowrap;
      }
    }
  }
}

@include tablet {
  .SignIn {
    &__circle {
      &-tip {
        &--icon {
          width: 30px;
          height: 30px;
        }
        &--text {
          font-size: 20px;
          padding-left: 10px;
        }
      }
    }
  }
}
