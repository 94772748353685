@use "src/style/main" as *;

.EditShortTermJob {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px 0;
  min-height: 680px;
  height: 100%;
  border: 0.5px solid rgba(19, 35, 66, 0.1);
  box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
  &__header {
    display: flex;
    flex-direction: column;
  }
  &__container {
    display: flex;
    border-bottom: 0.3px solid rgba(200, 200, 200, 0.3);
    padding: 0 25px;
  }
  &__container-item {
    font-size: 14px;
    line-height: 13.16px;
    font-family: "Manrope-Bold", sans-serif;
    color: #b2b2b2;
    margin-right: 30px;
    padding-bottom: 10px;
    cursor: pointer;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1.2px;
      bottom: -1px;
      left: 0;
      background: linear-gradient(265.82deg, #aee6ff 44.76%, #030ac0 58.06%);
      transform-origin: bottom right;
      transition: transform 300ms ease-out;
      visibility: hidden;
      z-index: 2;
    }

    &--active {
      color: #132342;
      &::before {
        transform: scaleX(1);
        transform-origin: bottom left;
        visibility: visible;
      }
    }
  }
  &__buttons-wrapper {
    display: flex;
    align-items: center;
    padding: 20px;
  }
  &__buttons {
    max-width: 400px;
    width: 100%;
    display: flex;
    margin-left: auto;
  }
  &__cancel-btn {
    background: #7e838b;
    margin-left: 10px;
    margin-right: 10px;
  }
  &__default-title {
    font-family: "Manrope-Bold", sans-serif;
    font-size: 20px;
    color: $primary-font-color;
    padding: 0 20px 20px 20px;
  }
  &__header-wrapper {
    display: flex;
    align-items: center;
    padding: 25px 20px;
  }
  &__form {
    margin-top: 25px;
    padding: 0 20px;
  }
  &__delete-button {
    justify-self: flex-start;
    margin-right: 10px;
    max-width: 195px;
  }
  &__item-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  &__label {
    font-size: 12px;
    line-height: 11px;
    font-family: "Manrope-Bold", sans-serif;
    color: #c6c6c6;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  &__value {
    font-size: 16px;
    line-height: 21px;
    font-family: "Manrope-Bold", sans-serif;
    color: #122342;
    cursor: not-allowed;
  }
  &__description {
    font-size: 16px;
    line-height: 22px;
    font-family: "Manrope-Bold", sans-serif;
    color: #122342;
    max-width: 550px;
    width: 100%;
  }
  &__images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  &__image {
    width: 75px;
    height: 75px;
    object-fit: contain;
    margin: 5px;
    border-radius: 6px;
  }

  &__shift-container {
    width: 263px;
    height: 51px;
    border-radius: 11px;
    border: 1px solid #0000001a;
    background-color: #fafbfb;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    cursor: not-allowed;
  }
  &__shift-item {
    font-size: 14px;
    line-height: 15px;
    font-family: "Manrope-Bold", sans-serif;
    color: #122342;
  }
  &__shift-item-small {
    font-size: 12px;
    line-height: 13px;
    font-family: "Manrope-SemiBold", sans-serif;
    color: #122342;
  }
  &__input-container {
    position: relative;
    max-width: 400px;
    width: 100%;
    cursor: pointer;
  }
  &__input {
    box-shadow: 0px 0px 20px 4px #cecece40;
  }

  &__input-error {
    font-family: "Manrope-Bold", sans-serif;
    color: #dd291e;
    font-size: 11px;
    padding: 5px 10px 0 10px;
  }
  &__textarea {
    min-height: 280px;
    box-shadow: 0px 0px 20px 4px #cecece40;
    &::placeholder {
      color: $primary-font-color;
      font-weight: 400;
    }
  }
  &__textarea-desc {
    padding: 10px 0;
  }
  &__images {
    padding: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 420px;
  }
  &__image-preview-container {
    position: relative;
    width: 90px;
    height: 90px;
  }
  &__image-preview {
    margin: 5px;
    border-radius: 6px;
    &:hover {
      cursor: pointer;
    }
  }
  &__delete-icon {
    position: absolute;
    width: 35px;
    height: 35px;
    top: 0;
    left: -5px;
    cursor: pointer;
  }
  &__images-placeholder {
    width: 90px;
    height: 91px;
    border-radius: 6px;
    border: 0.1px solid rgba(19, 35, 66, 0.1);
    box-shadow: 0px 0px 20px 4px rgba(206, 206, 206, 0.2509803922);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 10px 5px 2px;
  }
  &__add-icon {
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
  }
  &__title {
    font-size: 20px;
    line-height: 19px;
    font-family: "Manrope-Bold", sans-serif;
    color: #122342;
  }
  &__back-icon {
    width: 18px;
    height: 18px;
    rotate: 90deg;
    margin-right: 15px;

    &:hover {
      cursor: pointer;
    }
  }
  &__shift-container {
    width: 263px;
    height: 51px;
    border-radius: 11px;
    border: 1px solid #0000001a;
    background-color: #fafbfb;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }
  &__shift-item {
    font-size: 14px;
    line-height: 15px;
    font-family: "Manrope-Bold", sans-serif;
    color: #122342;
  }
  &__shift-item-small {
    font-size: 12px;
    line-height: 13px;
    font-family: "Manrope-SemiBold", sans-serif;
    color: #122342;
  }
}

@include mobile {
  .EditShortTermJob {
    &__buttons-wrapper {
      flex-direction: column-reverse;
    }
    &__delete-button {
      margin-top: 20px;
      margin-right: 0;
      max-width: 440px;
    }
    &__buttons {
      flex-direction: column-reverse;
      max-width: 460px;
      width: 100%;
      display: flex;
    }
    &__cancel-btn {
      margin-top: 20px;
      margin-left: 0;
      margin-right: 0;
    }
  }
}
