@use "src/style/main" as *;

.ShortTermJobCard {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 4px #cecece40;
  border: 0.1px solid rgba(200, 200, 200, 0.1);
  padding-bottom: 20px;
  position: relative;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 0.2px solid rgba(215, 215, 215, 0.2);
    &:hover {
      cursor: pointer;
    }
  }
  &__header-pro {
    background: linear-gradient(195deg, #c3e9f3 -17.97%, #2b9ab5 68.99%);
    padding: 3px;
    text-align: center;
    font-size: 14px;
    color: $white-color;
    border-radius: 10px 10px 0 0; /* Top-left and top-right rounded corners */
  }
  &__header-basic {
    background: linear-gradient(223deg, #aee7ff -6.49%, #030ac0 106.4%);
    padding: 3px;
    text-align: center;
    font-size: 14px;
    color: $white-color;
    border-radius: 10px 10px 0 0; /* Top-left and top-right rounded corners */
  }

  &__header-inactive {
    background: linear-gradient(223deg, #ffe3ae -6.49%, #ffb82e 106.4%);
    padding: 3px;
    text-align: center;
    font-size: 14px;
    color: $white-color;
    border-radius: 10px 10px 0 0; /* Top-left and top-right rounded corners */
  }

  &__expired-text {
    color: $warning-color;
  }

  &__job-title {
    font-size: 18px;
    color: #132342;
    font-family: "Manrope-Bold", sans-serif;
    line-height: 17px;
    word-break: break-word;
    white-space: pre-wrap;
    -moz-white-space: pre-wrap;
    margin-right: 5px;
  }

  &__more-vert-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__new-label {
    font-family: "Manrope-Bold", sans-serif;
    color: $white-color;
    font-size: 8px;
    background: $primary-color;
    border-radius: 3px;
    padding: 3px;
    //margin-left: 10px;
    text-transform: uppercase;
    //text-align: center;
  }

  &__job-price {
    font-size: 18px;
    color: #132342;
    font-family: "Manrope-Bold", sans-serif;
    line-height: 20px;
  }
  &__body {
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__city {
    color: #132342;
    font-family: "Manrope-Bold", sans-serif;
    font-size: 10px;
    line-height: 9.4px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    border: 1px solid #030ac0;
    border-radius: 4px;
    padding: 5px 8px 4px;
  }
  &__applicants-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
    padding: 0 28px;
    p {
      font-size: 12px;
      line-height: 16px;
      color: #132342;
    }
  }
  &__status-icon {
    cursor: pointer;
  }
  &__applicants-status {
    font-size: 12px;
    line-height: 16px;
    color: #132342;
    margin-left: 5px;
  }
  &__clock-in-status {
    margin-left: 5px;
    &--assigned {
      color: #1fdef7;
    }
    &--in-progress {
      color: #3fff80;
    }
    &--did-not-attend {
      color: #dd291e;
    }
    &--in-progress-yellow {
      color: #ffc82e;
    }
    &--completed {
      color: #1fdef7;
    }
  }
  &__card-total {
    font-size: 14px;
    line-height: 1.69;
    font-family: "Manrope-Bold", sans-serif;
    color: #1fdef7;
    background-color: #131a28;
    box-shadow: 0px -1px 1px 0px #131a283b;
    text-transform: uppercase;
    padding: 0 10px;
    border-radius: 5px;
    text-transform: uppercase;
    width: fit-content;
  }
  &__job-status {
    &--assigned {
      color: #1fdef7;
    }
    &--in-progress {
      color: #3fff80;
    }
    &--did-not-attend {
      color: #dd291e;
    }
    &--in-progress-yellow {
      color: #ffc82e;
    }
    &--completed {
      color: #1fdef7;
    }
  }
  &__completed-icon {
    margin-right: 5px;
    margin-left: 0;
    width: 14.5px;
  }
  &__ongoing {
    color: #132342;
    font-family: "Manrope-Bold", sans-serif;
    font-size: 12px;
    line-height: 16px;
    position: relative;
    margin-left: 20px;
    &::before {
      content: "";
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #f8b533;
      top: 4px;
      bottom: 0;
      left: -10px;
    }
  }
  &__upcoming {
    color: #132342;
    font-family: "Manrope-Bold", sans-serif;
    font-size: 12px;
    line-height: 16px;
    position: relative;
    margin-left: 20px;
    &::before {
      content: "";
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #030ac0;
      top: 4px;
      bottom: 0;
      left: -10px;
    }
  }
  &__upcoming--started {
    color: #132342;
    font-family: "Manrope-Bold", sans-serif;
    font-size: 12px;
    line-height: 16px;
    position: relative;
    margin-left: 20px;
    &::before {
      content: "";
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: $error-color;
      top: 4px;
      bottom: 0;
      left: -10px;
    }
  }
  &__completed {
    color: #132342;
    font-family: "Manrope-Bold", sans-serif;
    font-size: 12px;
    line-height: 16px;
    position: relative;
    margin-left: 20px;
    &::before {
      content: "";
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #03c02d;
      top: 4px;
      bottom: 0;
      left: -10px;
    }
  }

  &__show-btn {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: fit-content;
    height: 38px;
    padding: 10px 6px;
    border-radius: 7px;
    background: $white-color;
    border: 0.5px solid rgba(19, 35, 66, 0.1);
    box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
    text-transform: uppercase;
    &--center {
      right: 25%;
      left: 25%;
    }
    h4 {
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.8px;
      color: $primary-font-color;
    }
    &:hover {
      cursor: pointer;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
  }

  &__shift-card-body-another-shift {
    background-color: transparent;
    border-radius: 8px;
    padding: 6px 10px;
    height: 20px;
    margin: 0 20px -25px;
    box-shadow: 0px 0px 20px 4px #cecece40;
    border: 0.1px solid rgba(19, 35, 66, 0.1);
    z-index: 0;
  }
  &__shift-card-body {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 6px 10px;
    margin: 10px 20px 15px;
    box-shadow: 0px 0px 20px 4px #cecece40;
    border: 0.1px solid rgba(19, 35, 66, 0.1);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__shift-card-start,
  &__shift-card-end {
    font-size: 12px;
    line-height: 13px;
    font-family: "Manrope-Bold", sans-serif;
    text-transform: uppercase;
  }
  &__person-img {
    width: 10px;
    height: 12px;
    margin-right: 5px;
  }
  &__applicants-images {
    display: flex;
    align-items: center;
    padding-left: 25px;
  }
  &__person-image-wrappper {
    width: 38px;
    height: 38px;
    margin-right: 5px;
    border: 1px solid #1e2ecb;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  &__applicant-img {
    padding-top: 5px;
    width: 29px;
    height: 42px;
  }
  &__applicants {
    display: flex;
    align-items: center;
    p {
      font-size: 12px;
      line-height: 16px;
      color: #132342;
    }
  }
  &__shift-card-total {
    font-size: 10px;
    line-height: 11px;
    font-family: "Manrope-Bold", sans-serif;
    color: #1fdef7;
    background-color: #131a28;
    box-shadow: 0px -1px 1px 0px #131a283b;
    text-transform: uppercase;
    padding: 3px 5px;
    border-radius: 5px;
  }
  &__edit-button {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: fit-content;
    height: 38px;
    padding: 10px 6px;
    border-radius: 7px;
    background: $white-color;
    border: 0.5px solid rgba(19, 35, 66, 0.1);
    box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
    text-transform: uppercase;
    h4 {
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.8px;
      color: $primary-font-color;
    }
    &:hover {
      cursor: pointer;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
  }
  &__applicants-text {
    background-color: #f8ff00;
    padding: 2px;
  }
  &__available-job-applicants-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__available-job-hours-count {
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    span {
      font-size: 12px;
      padding: 0 2px;
      background-color: #fe8585;
    }
  }
  &__clock-icon {
    width: 13px;
    height: 13px;
    margin-right: 2px;
  }
}
