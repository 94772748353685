@use "src/style/main" as *;

.InvoicesContainer {
  background: $white-color;
  border-radius: 10px;
  border: 0.5px solid rgba(19, 35, 66, 0.1);
  box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);

  &__head {
    padding: 20px;
    border-bottom: 0.5px solid rgba(19, 35, 66, 0.1);
    &-title {
      font-family: "Manrope-Bold", sans-serif;
      font-size: 20px;
      color: $primary-font-color;
    }
  }

  &__body {
    padding: 20px;
  }
}

.Invoices {
  &__noData {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Manrope-Bold", sans-serif;
    color: $primary-font-color;
  }

  &__loader {
    height: 300px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }

  &__title {
    font-family: "Manrope-Bold", sans-serif;
    font-size: 18px;
    color: $primary-font-color;
    display: flex;
    justify-content: center;
    align-items: center;

    &-back {
      width: 18px;
      height: 18px;
      rotate: 90deg;
      margin-right: 15px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
