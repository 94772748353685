@use "src/style/main" as *;

.SignUpForm {
  display: flex;
  align-items: flex-start;
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: $white-color;
  }
  &::-webkit-scrollbar-thumb {
    background: $secondary-font-color;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: darken($secondary-font-color, 10%);
  }

  &__container {
    width: 100%;
  }
  &__title {
    font-family: "Manrope-Bold", sans-serif;
    color: $primary-font-color;
    text-align: left;
    margin-bottom: 40px;
    margin-right: 10px;
    &-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  &__help {
    margin-top: 5px;
  }

  &__google {
    &-spinner {
      width: 20px;
      padding: 3px;
      aspect-ratio: 1;
      border-radius: 50%;
      background: $black-color;
      --_m: conic-gradient(#0000 10%, #000),
        linear-gradient(#000 0 0) content-box;
      -webkit-mask: var(--_m);
      mask: var(--_m);
      -webkit-mask-composite: source-out;
      mask-composite: subtract;
      animation: l3 1s infinite linear;
    }
    @keyframes l3 {
      to {
        transform: rotate(1turn);
      }
    }

    &-container {
      width: 100%;
      background: $white-color;
      height: 69px;
      border: 1px solid #747775;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        cursor: pointer;
      }
    }
    &-icon {
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
    &-text {
      color: $black-color;
      font-family: "Roboto-Medium", sans-serif;
      letter-spacing: 0.3px;
    }
  }
  &__or {
    color: $secondary-font-color;
    text-align: center;
    margin: 15px 0;
    font-size: 14px;
  }

  &__input {
    margin-bottom: 20px;
    &-error {
      text-align: right;
      margin: 5px 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $error-color;
    }
    &-description {
      text-align: left;
      margin: 5px 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $secondary-font-color;
    }
  }

  &__checkbox {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-container {
      display: flex;
      align-items: center;
      margin-right: 20px;
    }

    &-text {
      margin-left: 10px;
      font-family: "Manrope-SemiBold", sans-serif;
      color: $primary-font-color;
      & > span {
        text-decoration: underline;

        &:hover {
          cursor: pointer;
        }
      }
    }

    &-error {
      text-align: right;
      margin: 5px 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $error-color;
    }
  }

  &__button {
    margin-top: 40px;
  }

  &__link {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 30px;

    &-text {
      font-family: "Manrope-Bold", sans-serif;
      color: $primary-font-color;

      & > span {
        font-family: "Manrope-ExtraBold", sans-serif;
        padding-left: 2px;
        color: $primary-color;
        text-decoration: underline;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

@include screen-2k {
  .SignUpForm {
    align-items: center;

    &__container {
      margin-bottom: 15%;
    }
  }
}

@include mobile {
  .SignUpForm {
    &__container {
      margin-bottom: 80px;
    }
  }
}
