@use "src/style/main" as *;

.Sidebar {
  width: 240px;
  position: fixed;
  height: 100vh;
  z-index: 10;
  background-color: $white-color;
  box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
  top: 0;
  left: 0;
  bottom: 0;

  &__head {
    height: 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }

  &__logo {
    width: 136px;
    padding-bottom: 7px;
  }

  &__close {
    width: 20px;
    height: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  &__body {
    margin-top: 20px;
  }

  &__nav {
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    &:hover {
      cursor: pointer;
    }

    &-container {
      display: flex;
      align-items: center;
    }

    &-icon {
      width: 20px;
      height: 20px;
      margin: 0 20px;
    }

    &-label {
      font-family: "Manrope-Bold", sans-serif;
      color: $secondary-font-color;

      &--active {
        color: $primary-font-color;
      }
    }

    &-new-label {
      font-family: "Manrope-Bold", sans-serif;
      color: white;
      font-size: 8px;
      margin-left: 10px;
      text-transform: uppercase;
      text-align: center;
      background: $primary-color;
      border-radius: 3px;
      padding: 3px;
    }

    &-border {
      width: 2px;
      height: 60%;
      background: transparent;

      &--active {
        background: linear-gradient(208deg, #aee7ff -9.13%, #030ac0 97.4%);
      }
    }
  }
}

@include tablet {
  .Sidebar {
    width: 62px;
  }
}

@include mobile {
  .Sidebar {
    z-index: 30;
    width: 100%;
  }
}
