@use "src/style/main" as *;

.ListUsers {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px 0 40px;
  min-height: 680px;
  height: 100%;
  border: 0.5px solid rgba(19, 35, 66, 0.1);
  box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);

  &__spinner {
    width: 20px;
    padding: 3px;
    margin-right: 20px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: $primary-font-color;
    --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: l3 1s infinite linear;
  }
  @keyframes l3 {
    to {
      transform: rotate(1turn);
    }
  }

  &__input {
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    flex-grow: 1;
    width: auto;
    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      & > :first-child {
        flex-grow: 8; /* Adjusted for 80% */
        flex-basis: 80%;
      }

      & > :last-child {
        flex-grow: 2; /* Adjusted for 20% */
        flex-basis: 20%;
      }
      @media screen and (max-width: 768px) { /* Adjust breakpoint as needed */
        flex-direction: column;
        gap: 10px; /* Reduce gap for column layout */

        & > :first-child,
        & > :last-child {
          flex-grow: unset;
          flex-basis: unset;
          width: 100%; /* Make both inputs full width */
        }
      }
    }
    &-error {
      text-align: right;
      margin: 5px 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $error-color;
    }
    &-description {
      text-align: left;
      margin: 5px 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $secondary-font-color;
      font-size: 12px;
    }
  }

  &__cards-wrapper {
    //padding: 0 25px;
    //display: grid;
    //grid-template-columns: 294px 294px 294px;
    //gap: 20px;
    //align-items: stretch;
    //position: relative;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }

  &__no-content {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    font-family: "Manrope-Bold", sans-serif;
    color: #122342;
    margin-top: 150px;
  }
  &__header {
    display: flex;
    flex-direction: column;
  }
  &__title {
    font-size: 20px;
    line-height: 19px;
    font-family: "Manrope-Bold", sans-serif;
    color: #122342;
    padding-right: 20px;
  }
  &__title-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  &__reload-icon {
    margin-top: 2px;
    margin-right: 20px;
    width: 18px;
    height: 18px;
    &:hover {
      cursor: pointer;
    }
  }
  &__reload-icon--disable {
    opacity: 0.4;
    &:hover {
      cursor: not-allowed;
    }
  }
  &__back-icon {
    width: 18px;
    height: 18px;
    rotate: 90deg;
    margin-right: 5px;
    margin-left: 15px;

    &:hover {
      cursor: pointer;
    }
  }
  &__default-title {
    font-family: "Manrope-Bold", sans-serif;
    font-size: 20px;
    color: $primary-font-color;
    padding: 0 15px 0 5px;
  }
  &__container {
    display: flex;
    border-bottom: 0.3px solid rgba(200, 200, 200, 0.3);
    padding: 0 25px;
  }
  &__container-item {
    font-size: 14px;
    line-height: 13.16px;
    font-family: "Manrope-Bold", sans-serif;
    color: #b2b2b2;
    margin-right: 30px;
    padding-bottom: 10px;
    cursor: pointer;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1.2px;
      bottom: -1px;
      left: 0;
      background: linear-gradient(265.82deg, #aee6ff 44.76%, #030ac0 58.06%);
      transform-origin: bottom right;
      transition: transform 300ms ease-out;
      visibility: hidden;
      z-index: 2;
    }

    &--active {
      color: #132342;
      &::before {
        transform: scaleX(1);
        transform-origin: bottom left;
        visibility: visible;
      }
    }
  }
  &__buttons-container {
    display: flex;
    align-items: center;
    margin-top: 25px;
    padding-left: 20px;
  }
  &__not-assigned-btn {
    border: 0.2px solid rgba(19, 35, 66, 0.2);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 4px 0px;
    border-radius: 21px;
    font-size: 14px;
    line-height: 13px;
    font-family: "Manrope-Bold", sans-serif;
    color: #132342;
    padding: 14.5px 22px;
    margin-right: 10px;

    &--active {
      background: linear-gradient(222.56deg, #aee7ff -6.49%, #030ac0 106.4%);
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 4px 0px;
      color: #ffffff;
      border: none;
    }
    &:hover {
      cursor: pointer;
      box-shadow: 0px 0px 20px 4px #cecece40;
    }
  }
  &__assigned-btn {
    border: 0.2px solid rgba(19, 35, 66, 0.2);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 4px 0px;
    border-radius: 21px;
    font-size: 14px;
    line-height: 13px;
    font-family: "Manrope-Bold", sans-serif;
    color: #132342;
    padding: 13.5px 14px;

    &--active {
      background: linear-gradient(222.56deg, #aee7ff -6.49%, #030ac0 106.4%);
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 4px 0px;
      color: #ffffff;
      border: none;
    }
    &:hover {
      cursor: pointer;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
  }
  &__header-chips {
    display: flex;
    align-items: start;
    padding-top: 10px;
    padding-left: 20px;
    overflow-x: auto;
    scrollbar-width: none;
    white-space: nowrap;
  }
  &__header-wrapper {
    display: flex;
    align-items: center;
    padding: 25px 20px;
  }
  &__back-icon {
    width: 18px;
    height: 18px;
    rotate: 90deg;
    margin-right: 15px;

    &:hover {
      cursor: pointer;
    }
  }
  &__status-btn {
    border: 0.2px solid rgba(19, 35, 66, 0.2);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 4px 0px;
    border-radius: 21px;
    font-size: 14px;
    line-height: 13px;
    font-family: "Manrope-Bold", sans-serif;
    color: #132342;
    padding: 14px 17px;
    &:first-child {
      margin-right: 10px;
    }
    &--active {
      background: linear-gradient(222.56deg, #aee7ff -6.49%, #030ac0 106.4%);
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 4px 0px;
      color: #ffffff;
      border: none;
    }
    &:hover {
      cursor: pointer;
      box-shadow: 0px 0px 20px 4px #cecece40;
    }
  }
}

//@include tablet {
//  .ListUsers {
//    &__cards-wrapper {
//      display: grid;
//      grid-template-columns: 294px 294px;
//      gap: 20px;
//    }
//  }
//}
@include mobile {
  .ListUsers {
    &__container {
      padding: 0;
      border-top: 0.3px solid rgba(200, 200, 200, 0.3);
    }

    &__buttons-container {
      justify-content: center;
      margin-top: 20px;
      padding-left: 0;
    }

    &__container-item {
      margin-right: 0;
      width: 100%;
      padding: 20px;
      text-align: center;
    }

    //&__cards-wrapper {
    //  display: grid;
    //  grid-template-columns: 294px;
    //  gap: 20px;
    //}

    &__assigned-btn {
      padding: 10px;
      text-align: center;
      font-size: 13px;
    }
    &__not-assigned-btn {
      padding: 10px;
      text-align: center;
      font-size: 13px;
      margin-right: 20px;
    }

    &__status-btn {
      padding: 10px;
      text-align: center;
      font-size: 13px;
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}
