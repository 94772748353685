@use "src/style/main" as *;

.TextAreaInput {
  border-radius: 10px;
  background-color: $white-color;
  border: 0.1px solid rgba(19, 35, 66, 0.1);
  filter: drop-shadow(0px 0px 20px rgba(206, 206, 206, 0.1));
  padding: 20px;
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: $primary-font-color;
  font-family: "Manrope-Bold", sans-serif;
  outline: none;
  resize: none;
  &::placeholder {
    color: $secondary-font-color;
    font-family: "Manrope-Bold", sans-serif;
  }
  &--disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}
