@use "src/style/main" as *;

.Payments {
  background: $white-color;
  border-radius: 10px;
  border: 0.5px solid rgba(19, 35, 66, 0.1);
  box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);

  &__noData {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Manrope-Bold", sans-serif;
    color: $primary-font-color;
  }

  &__loader {
    height: 300px;
  }

  &__head {
    padding: 20px;
    border-bottom: 0.5px solid rgba(19, 35, 66, 0.1);
    &-title {
      font-family: "Manrope-Bold", sans-serif;
      font-size: 20px;
      color: $primary-font-color;
    }
  }

  &__body {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
}

.PaymentCardsCart {
  border-radius: 14px;
  border: 0.5px solid rgba(19, 35, 66, 0.1);
  background: $white-color;
  box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }

  &__title {
    color: $primary-font-color;
    font-size: 20px;
  }
  &__manage {
    color: $primary-font-color;
    font-size: 10px;
    letter-spacing: 1.2px;
    text-decoration-line: underline;
    text-transform: uppercase;
    &:hover {
      cursor: pointer;
    }
  }
  &__body {
    padding: 0 20px 20px 20px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }

  &__noData {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Manrope-Bold", sans-serif;
    color: $primary-font-color;
  }

  &__loader {
    height: 100px;
  }

  &__add {
    border-radius: 14px;
    border: 0.5px solid rgba(19, 35, 66, 0.1);
    background: $white-color;
    box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    &-round {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: linear-gradient(223deg, #aee7ff -6.49%, #030ac0 106.4%);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
    }

    &-plus {
      width: 20px;
      height: 20px;
    }

    &-text {
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1.12px;
    }
  }
}

.InvoicesCart {
  border-radius: 14px;
  border: 0.5px solid rgba(19, 35, 66, 0.1);
  background: $white-color;
  box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
  width: 100%;

  &__noData {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Manrope-Bold", sans-serif;
    color: $primary-font-color;
  }

  &__loader {
    height: 100px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }

  &__title {
    color: $primary-font-color;
    font-size: 20px;
  }
  &__viewAll {
    color: $primary-font-color;
    font-size: 10px;
    letter-spacing: 1.2px;
    text-decoration-line: underline;
    text-transform: uppercase;
    &:hover {
      cursor: pointer;
    }
  }
  &__body {
    padding: 0 20px 20px 20px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
}

@include tablet {
  .YourPlan {
    &__body {
      grid-template-columns: 1fr;
    }
  }
}

@include mobile {
  .Payments {
    &__body {
      grid-template-columns: 1fr;
    }
  }

  .PaymentCardsCart {
    border: none;
    box-shadow: none;
    &__header {
      padding: 10px 0;
    }
    &__body {
      padding: 0;
    }
  }
  .InvoicesCart {
    border: none;
    box-shadow: none;
    &__header {
      padding: 10px 0;
    }
    &__body {
      padding: 0;
    }
  }
}
