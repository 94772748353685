@use "src/style/main" as *;

.MultiFileUploader {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  &__container {
    width: fit-content;
    max-width: 100%;
    border-radius: 10px;
    background-color: $white-color;
    stroke-width: 0.1px;
    stroke: rgba(19, 35, 66, 0.39);
    filter: drop-shadow(0px 0px 20px rgba(206, 206, 206, 0.25));
    padding: 40px;
  }
  &__input-label {
    font-family: "Manrope-Bold", sans-serif;
    font-size: 25px;
    color: $primary-font-color;
    margin-left: 5px;
  }

  &__input {
    margin-bottom: 20px;
    margin-left: 5px;

    &-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin: 40px 0 20px;
      position: relative;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
  }
  &__error {
    margin: 10px;
    font-family: "Manrope-Bold", sans-serif;
    color: #dd291e;
    font-size: 12px;
  }
  &__submit-btn, &__cancel-btn h4 {
     letter-spacing: 0.8px;
  }
  &__close {
    position: absolute;
    right: 25px;
    top: 25px;
    &:hover {
      cursor: pointer;
    }
  }
}
